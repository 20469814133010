import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Container from "../../../../layout/gridContainer";
import FormControl from "@mui/material/FormControl";
import { TextField, Button } from "@mui/material";
import GenericTable from "../../../../components/GenericTable";
import { PaginationGeneric } from "react-generic-pagination";
import { useNavigate } from "react-router-dom";
import AddTransfer from "./addTransfer";
import { TransferService } from "../../../../api/inventory/transfersService";
import { GenericEmptyTable } from "../../../../components/GenericEmptyTable";
import CircularProgress from "@mui/material/CircularProgress";
import TransferTable from "./transferTable";
import BasicTable from "../../../../components/GenericTable";
import { useTableFilters } from "../../../../hooks/searchParams";
import PageParam from "../../../../components/PageParam";

const Transfers = () => {
  const [openModal, setOpenModal] = useState();
  const [totalpages, setTotalPages] = useState();
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState();
  const [params, setParams, handleSearch, handleSearchEmpty, UdateTable] =
    useTableFilters(TransferService, setTable, setLoading, "", "tab");

  return (
    <Container>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <h2>Transferencias</h2>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={() => setOpenModal(true)}
                  fullWidth
                  variant="contained"
                >
                  Crear
                </Button>
                <AddTransfer
                  openModal={openModal}
                  updateTransfers={UdateTable}
                  setOpenModal={setOpenModal}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="margin-scrap-table">
        {table?.data ? (
          <>
            <BasicTable
              className="marginTable"
              titles={["Almacen Origen", "Almacen Destino", "Fecha", "Estado"]}
            >
              {table.data.map((data, index) => (
                <TransferTable key={index} data={data} />
              ))}
            </BasicTable>
            <div className="margin">
              {/* <PaginationGeneric className="paginationStyle" pagina={1} total={totalpages} pageSetter={pageSetter}></PaginationGeneric> */}
              <PageParam
                totalpages={table.pagination}
                params={params}
                handleChange={handleSearch}
              />
            </div>
          </>
        ) : (
          <div className="model-null">
            {loading && <CircularProgress />}
            {!table && !loading && <GenericEmptyTable />}
          </div>
        )}
      </Grid>
    </Container>
  );
};

export default Transfers;
