import React, { useState, useEffect } from "react";
import BasicModal from "./GenericModal";
import Typography from "@mui/material/Typography";
import { TextField, Grid, Grid2, IconButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValBusinessAddressInfo,
  valSchBusinessAddress,
} from "../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import AddressManages, { ZipCode } from "../api/addressManages";
import { UseerrorOrsuccess, useNotification } from "../hooks/snackBarResponse";
import { useSnackbar } from "notistack";
import FormControl from "@mui/material/FormControl";
import { FormHelperText } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { GenericDrawer } from "./keskiCreator/genericDrawer";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { BusinessMetaData } from "../api/businessManages";

export function GenericAddressDrawer(props) {
  return (
    <GenericDrawer
      open={props.openDrawer}
      toggleDrawer={props.toggleDrawer}
      anchor={"right"}
      drawerWidth="30%"
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "8px 16px",
        }}
      >
        <Typography fontSize={"20px"} p={"8px 0"}>
          <b>{props.data.address ? "Editar" : "Agregar"} dirección</b>
        </Typography>

        <IconButton onClick={props.close}>
          <CloseSharpIcon fontSize="small" />
        </IconButton>
      </Grid>
      <GenericAddressForm
        UpdateInfo={props.UpdateInfo}
        data={props.data}
        target={props.target}
        endpointInstance={"addressmanage"}
      />
    </GenericDrawer>
  );
}

export function GenericAddressForm(props) {
  const [loading, setLoading] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [direction, setDirection] = useState("");
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const [modified, setModified] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const targets = {
    supplier: "supplier",
    business: "business",
    branch: "branch",
    client: "client",
  };

  /* console.log('target', target) */

  const UpdateAddress = async (id, formData) => {
    const services = {
      metadata: new BusinessMetaData(),
      addressmanage: new AddressManages(),
    };
    const service = services[props.endpointInstance];

    const response = await service.editDataAuth(id, formData, logout);
    if (response?.data) {
      sendNotification(UseerrorOrsuccess(response));
    } else {
      sendNotification(UseerrorOrsuccess(response));
    }
    props.UpdateInfo ? props.UpdateInfo() : console.log(response);
  };
  const SetAddress = async (formData) => {
    let setAddress = new AddressManages();
    const response = await setAddress.postDataAuth(formData, logout);
    if (response?.data) {
      sendNotification(UseerrorOrsuccess(response));
    } else {
      sendNotification(UseerrorOrsuccess(response));
    }
    props.UpdateInfo ? props.UpdateInfo() : console.log(response);
  };

  const formik = useFormik({
    initialValues: initValBusinessAddressInfo(props.data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchBusinessAddress()),
    onSubmit: async (formData, { resetForm }) => {
      if (targets[props.target]) {
        formik.setFieldValue(targets[props.target], props.data.id);
      }
      setLoading(true);
      /* console.log(formData)
      console.log(direction) */
      let longLati = direction.suburbs.find(
        (e) => e.name == formik.values.suburb
      );
      /* console.log(longLati) */
      if (longLati) {
        formik.values.map_lat = longLati.latitude;
        formik.values.map_lon = longLati.longitude;
      }

      if (props.data.address) {
        UpdateAddress(props.data.address.id, formData);
        formik.resetForm();
      } else {
        SetAddress(formData);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (props.data.address?.postal_code) {
      GetDirections(props.data.address.postal_code);
    }
  }, []);

  const GetDirections = (code) => {
    (async () => {
      if (code) {
        setLoadingCode(true);
        let irequestFilter = [];
        irequestFilter.push({ key: "code", value: `${code}` });
        let getDirec = new ZipCode();
        let response = await getDirec.filter(irequestFilter);

        if (response?.data.error) {
          console.log("error Generic");
          sendNotification({
            custom: "Codigo postal no valido",
            variant: "error",
          });
        }

        if (response?.data) {
          setDirection(response.data);
          formik.values.state = response.data.state;
          formik.values.city = response.data.city;
          setFieldsLocked(true); // Bloquear los campos después de obtener los datos
        }
        setLoadingCode(false);
      } else {
        enqueueSnackbar("Se necesita el código postal", {
          variant: "error",
          //autoHideDuration: 1000,
          //action
        });
      }
    })();
  };

  const [fieldsLocked, setFieldsLocked] = useState(false);
  return (
    <Grid
      p={"16px"}
      component={"form"}
      onSubmit={formik.handleSubmit}
      onChange={() => setModified(true)}
    >
      <TextField
        fullWidth
        name="street"
        label="Dirección (Calle, torre, empresa)"
        size="small"
        value={formik.values.street}
        onChange={formik.handleChange}
        error={formik.touched.street && Boolean(formik.errors.street)}
        helperText={formik.touched.street ? formik.errors.street : ""}
      />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="ext_number"
            label="No. Exterior"
            size="small"
            margin="normal"
            value={formik.values.ext_number}
            onChange={formik.handleChange}
            error={
              formik.touched.ext_number && Boolean(formik.errors.ext_number)
            }
            helperText={
              formik.touched.ext_number ? formik.errors.ext_number : ""
            }
          />
          <TextField
            fullWidth
            name="postal_code"
            margin="normal"
            label="Código Postal"
            size="small"
            value={formik.values.postal_code}
            onChange={formik.handleChange}
            error={
              formik.touched.postal_code && Boolean(formik.errors.postal_code)
            }
            helperText={
              formik.touched.postal_code ? formik.errors.postal_code : ""
            }
          />

          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            margin="normal"
          >
            <InputLabel
              error={formik.touched.suburb && Boolean(formik.errors.suburb)}
            >
              Colonia
            </InputLabel>
            <Select
              name="suburb"
              label="Colonia"
              value={formik.values.suburb}
              onChange={formik.handleChange}
              error={formik.touched.suburb && Boolean(formik.errors.suburb)}
            >
              {direction?.suburbs &&
                direction?.suburbs.map((data) => (
                  <MenuItem
                    key={data.id}
                    value={
                      data.name
                    } /* onClick={()=>{formik.values.map_lat = data.latitude; formik.values.map_lon = data.longitude}} */
                  >
                    {data.name}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText
              sx={{
                display: formik.touched.suburb ? "" : "none",
                color: "error.main",
              }}
            >
              {formik.touched.suburb ? formik.errors.suburb : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="int_number"
            label="No. Interior"
            size="small"
            margin="normal"
            value={formik.values.int_number}
            onChange={formik.handleChange}
            error={
              formik.touched.int_number && Boolean(formik.errors.int_number)
            }
            helperText={
              formik.touched.int_number ? formik.errors.int_number : ""
            }
          />
          <LoadingButton
            fullWidth
            loading={loadingCode}
            sx={{ borderRadius: 60, margin: "17px 0 10px" }}
            variant="contained"
            disabled={!formik.values.postal_code}
            onClick={() => {
              GetDirections(formik.values.postal_code);
            }}
          >
            Validar
          </LoadingButton>
          <TextField
            fullWidth
            name="city"
            label="Ciudad/Municipio"
            disabled={fieldsLocked} // Disabilty the camp if fieldsLocked is true
            size="small"
            margin="normal"
            value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city ? formik.errors.city : ""}
          />
        </Grid>
      </Grid>

      {/*  -------------------------------------------- */}
      <TextField
        fullWidth
        name="phone"
        label="Teléfono"
        type={"number"}
        size="small"
        margin="normal"
        value={formik.values.phone}
        onChange={formik.handleChange}
        error={formik.touched.phone && Boolean(formik.errors.phone)}
        helperText={formik.touched.phone ? formik.errors.phone : ""}
      />

      <TextField
        fullWidth
        name="street_between"
        label="Calles con la que se cruza "
        size="small"
        margin="normal"
        value={formik.values.street_between}
        onChange={formik.handleChange}
        error={
          formik.touched.street_between && Boolean(formik.errors.street_between)
        }
        helperText={
          formik.touched.street_between ? formik.errors.street_between : ""
        }
      />

      <Grid display={"flex"} justifyContent="flex-end">
        <LoadingButton
          loading={loading}
          disabled={!modified}
          type="submit"
          variant="contained"
          sx={{ color: "#fff", width: "48%", m: "16px 0", borderRadius: 60 }}
        >
          {props.data.address ? "Editar" : "Agregar"}
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
