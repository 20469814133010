import React, { useEffect, useState, useMemo } from "react";
import MenuDrawer from "../layout/menuDrawer";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import ChangePasswordTest from "../pages/changePassword";
import Login from "../pages/login";
import jwtDecode from "jwt-decode";
import * as context from "user-context-fran-dev";
import RecoverPassword from "../pages/recoverPassword";
import ResetPassword from "../pages/resetPassword";
import IconButton from "@mui/material/IconButton";
import Inicio from "../pages/home/home";
/* import AdminHome from '../pages/admin/adminHome'; */
import AdminUsers from "../pages/admin/adminUsers";
import MyBusiness from "../pages/admin/adminBusiness";
import Politics from "../pages/landing/landingPolitics";
import Settings from "../pages/admin/adminSettings";
import UserDetails from "../pages/admin/adminUserDetail";
import BranchDetails from "../pages/admin/adminBranchDetails";
import AdminBranch from "../pages/admin/adminBranch";

/* import Landing from '../pages/landing/landing'; */
import LandingHome from "../pages/landing/landingHome";
import Brief from "../pages/landing/brief";
import CatalogArticles from "../pages/catalog/catalogArticles";
/* import CatalogHome from '../pages/catalog/catalogHome'; */
import CatalogArticleDetail from "../pages/catalog/catalogArticleDetail";
/* import InventoryHome from '../pages/inventory/inventoryHome'; */
import InventorySuppliers from "../pages/inventory/inventorySuppliers";
import InventoryPurchase from "../pages/inventory/inventoryPurchase";
import SuppliersDetails from "../pages/inventory/suppliers/details/details";
import PurchasesDetails from "../pages/inventory/purchase/details/purchaseDetails";
import InventarioWarehouse from "../pages/inventory/inventaryWarehouse";
import InventoryWHPurchaseDetail from "../pages/inventory/warehouse/detailPurchase";
import InventoryWHSaleDetail from "../pages/inventory/warehouse/detailSale";
import InventoryInventory from "../pages/inventory/inventoryInventory";
import SuppliersArticles from "../pages/inventory/suppliers/details/articles";
import InventoryCustomer from "../pages/inventory/inventoryCustomer";
import InventorySales from "../pages/inventory/inventorySales";
import CustomerDetails from "../pages/inventory/customer/details/details";
import SalesDetails from "../pages/inventory/sales/details/salesDetails";
import InventoryTransformation from "../pages/inventory/inventoryTransformation";
import InventoryRecipes from "../pages/inventory/inventoryRecipes";
import InventoryRecipesDetail from "../pages/inventory/recipes/detail/recipeDetail";
import TransformationDetails from "../pages/inventory/transformation/detail/transformationDetail";
import ScrapDetails from "../pages/inventory/operations/scrap/detailScrap";
import AdjustmentDetails from "../pages/inventory/operations/adjustment/detailAdjustment";
import DetailProduction from "../pages/inventory/warehouse/detailProduction";
import DetailTransfer from "../pages/inventory/warehouse/detailTransfer";
import AdminWareHouse from "../pages/inventory/warehouse/adminWarehouse";
import InventorySettings from "../pages/inventory/inventorySettings";
import PsAdmin from "../pages/posAdmin/psAdminHome";
import CashRegister from "../pages/posAdmin/psAdminCashRegisters";
import CashRegisterDetail from "../pages/posAdmin/cashRegisterDetails/cashRegisterDetail";
import PsAdminSettings from "../pages/posAdmin/psAdminSettings";
import Discounts from "../pages/posAdmin/psAdminDiscounts";
import StepperWrapper from "../pages/configBusiness/configStepperWraper";
import TransferDetails from "../pages/inventory/operations/transfers/detailTransfer";
import fetchData from "../testAxios";

/* import { StoreHome } from '../pages/store/storeHome'; */
import { StoreOrders } from "../pages/store/storeOrders";
import GeneralSettings from "../pages/store/storeGeneral";
import { StoreOrderDetail } from "../pages/store/order/storeOrderDetail";
import StoreShipments from "../pages/store/storeShipments";
import OperationRules from "../pages/store/operationRules/operationRules";
import { StorePayments } from "../pages/store/storePayments";
import { StoreStatus } from "../pages/store/storeStatus";
import StorePos from "../pages/store/storePos";
import StoreProducts from "../pages/store/storeProducts";
/* import StoreInfo from '../pages/store/storeInfo'; */
import Creator from "../components/keskiCreator/Creator";
/* import { MQTT } from '../components/mqtt/Mqtt'; */
import ViewOrder from "../pages/ordersView/viewOrder";
import { useSnackbar } from "notistack";
import ErorPages from "../pages/errorPages";
import LoadPage from "../pages/loadPage";

import { APPNAME } from "../utils/constants";
import LandingMetaData from "../pages/landing/landingMetaData";
import StoreCustomers from "../pages/store/storeCustomers";
// import mqtt from "mqtt/dist/mqtt";
import DeleteIcon from "@mui/icons-material/Delete";
import { BusinessSettings } from "../api/businessManages";
import { DomainList } from "../api/landing/domainList";
import ImageDetails from "../components/keskiCreator/imageDetails";
import PosDrawer from "../layout/pos/posDrawer";
import PosHome from "../pages/pos/posView/posHome";
import PayProcess from "../pages/pos/processPaymant/processPaymant";
import CatalogFilter from "../pages/catalog/articleDetail/articleFilter";
import SearchBusiness from "../pages/searchBusiness";
import { Chat } from "../pages/chat/chat";

function Rutes({ mode, changeMode }) {
  const [auth, setAuth] = useState(null);
  const [reloadUser, setReloadUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  /* const [msg, setmsg] = useState('') */
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [url, setUrl] = useState(null);
  const [online, setOnline] = useState(false);
  const [creatorRouter, setCreatorRouter] = useState(null);
  const [domain, setDomain] = useState(null);
  const [listUpdate, seListUpdate] = useState(false);
  const [openPurchaseList, setOpenPurchaseList] = useState(true);
  //const navigate = useNavigate();

  // const client_url = 'ws://broker.hivemq.com:8000/mqtt';
  let appname = APPNAME.split(" ").join("");
  const topic = `/codenation-studio/admin/prod/${appname}`;

  // var options = {
  //   host: 'ws://08838738991c463c8a60807c98c48d32.s2.eu.hivemq.cloud:8884/mqtt',
  //   port: 8884,
  //   protocol: 'mqtts',
  //   username: 'frank1234',
  //   password: 'CnsJmtz0414',
  //   reconnectPeriod: 10000
  // }

  // const client = mqtt.connect(options.host, options);
  // client.subscribe(topic, { qos: 1 });

  document.title = APPNAME;

  const login = (data, user) => {
    context.login(data, user, setAuth);
  };

  const setBasePath = (basePathUrl) => {
    context.setBaseUrl(basePathUrl);
  };

  const logout = () => {
    context.logout(auth, setAuth);
    setReloadUser(true);
    // client.end()
  };

  const updateSalesList = () => {
    console.log("actualizar lista");
    seListUpdate(!listUpdate);
  };

  // const mqttPublish = (payload) => {
  //   client.publish(topic, payload, (err) => {
  //     if (err) {
  //       console.error('Error publishing message:', err);
  //     } else {
  //       console.log('Message published successfully');
  //     }
  //   });
  // }

  function setCashRegister(cashRegister) {
    //console.log('set localstorage', JSON.stringify(cashRegister))
    localStorage.setItem("cashregister", JSON.stringify(cashRegister));
    setReloadUser(true);
  }

  const autData = useMemo(
    () => ({
      auth,
      login,
      setBasePath,
      logout,
      setReloadUser,
      setCashRegister,
      // mqttPublish,
      online,
    }),
    [auth, online]
  );
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    fetchData;
  }, []);

  const action = (snackbarId) => {
    return (
      <>
        <IconButton
          aria-label="delete"
          onClick={() => {
            closeSnackbar(snackbarId);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </>
    );
  };

  // useEffect(() => {

  //   client.on('message', function (topic, message) {
  //     setOnline(true)
  //     var isValidJSON = true;
  //     try { JSON.parse(message) } catch { isValidJSON = false }
  //     if (isValidJSON) {
  //       let object = JSON.parse(message)
  //       console.log(object.link)
  //       const actionLink = (snackbarId) => {
  //         return (
  //           <>
  //             <IconButton aria-label="delete" onClick={() => { closeSnackbar(snackbarId) }}>
  //               <DeleteIcon />
  //             </IconButton >
  //             <IconButton aria-label="delete" onClick={() => { window.location.href = `${object.link}` }}>
  //               <LaunchIcon />
  //             </IconButton >

  //           </>
  //         )
  //       }
  //       setUrl(object.link)
  //       enqueueSnackbar(`${object.msg}`, {
  //         variant: object.variant ? object.variant : "info",
  //         persist: true,
  //         action: object.link ? actionLink : action,
  //       })
  //       //client.end()
  //     }
  //     else {
  //       enqueueSnackbar(`${message}`, {
  //         variant: "info",
  //         action
  //       })
  //     }
  //   }
  //   )

  // }, [])

  // useEffect(() => {

  //   client.on('offline', () => {
  //     console.log('Client is offline');
  //     setOnline(false)
  //   });

  //   client.on('reconnect', () => {
  //     console.log('Reconnecting to MQTT broker');
  //     setOnline(false)
  //   });

  //   client.on('end', () => {
  //     console.log('Connection to MQTT broker ended');
  //     setOnline(false)
  //   });

  //   client.on('connect', () => {
  //     setOnline(true)
  //     console.log('Connection to MQTT started');
  //     //setReloadUser(false)
  //   });

  // }, [client])

  useEffect(() => {
    setLoading(true);

    const token = context.getToken();
    const user = context.getUser();
    if (token) {
      setAuth({
        token,
        idUser: jwtDecode(token).id,
        user: user,
      });
    } else {
      setAuth(null);
    }
    setReloadUser(false);
    setLoading(false);
  }, [setReloadUser]);

  const GetAPI = () => {
    (async () => {
      let useBusines = new BusinessSettings();
      let response = await useBusines.getAllByIdAuth("1", logout);
      setDomain(response?.data.domain);
    })();
  };
  const GetListPages = async (domain) => {
    let irequestFilter = [];
    irequestFilter.push({ key: "domain", value: `${domain}` }); // TODO:  test.74-208-117-143.plesk.page ${domain}
    let getArticle = new DomainList();
    let response = await getArticle.filter(irequestFilter);

    let newData = [];
    for (const key in response.data) {
      if (response.data[key].is_active) {
        newData.push({
          text: response.data[key].name,
          url: response.data[key].link.replace("/", ""),
          id: key,
        }); //{text: element.name, url: element.replace("/", "")}
      }
    }
    Object.values(response.data).map((element, index, key) => {
      console.log(key);
    });
    setCreatorRouter(response.data);
    console.log(Object.values(response.data));
    console.log(newData);
    setCreatorRouter(newData);
  };

  useEffect(() => {
    GetAPI();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (domain) {
      GetListPages(domain);
    }
  }, [domain]);

  return (
    <context.AuthContext.Provider value={autData}>
      <BrowserRouter /* basename='/admin' */>
        <div>
          {auth && (
            <Routes>
              <Route
                path={"/error"}
                element={<ErorPages changeMode={changeMode} mode={mode} />}
              />
              <Route
                path={"/home/*"}
                element={
                  <Inicio changeMode={changeMode} mode={mode} admin={1} />
                }
              />
              <Route
                path={"/business-config"}
                element={<StepperWrapper changeMode={changeMode} mode={mode} />}
              />
              <Route
                path={"/changes-password"}
                element={<ChangePasswordTest theme={mode} />}
              />

              <Route
                path="/administration"
                element={
                  <MenuDrawer
                    mode={mode}
                    changeMode={changeMode}
                    reload={reload}
                  />
                }
              >
                <Route path="home/*" element={<AdminUsers />} />
                {/*  <AdminHome/> */}
                <Route path="users/*" element={<AdminUsers />} />
                <Route
                  path="users/detail/:id"
                  element={<UserDetails setReload={setReload} />}
                />
                <Route path="business" element={<MyBusiness />} />
                <Route path="branch/*" element={<AdminBranch />} />
                <Route
                  path="branch/detail/:id"
                  element={<BranchDetails setReload={setReload} />}
                />
                {/* <Route path='politics' element={<Politics/>}/> */}
                <Route path="settings" element={<Settings />} />
              </Route>

              <Route
                path="/landing"
                element={<MenuDrawer mode={mode} changeMode={changeMode} />}
              >
                <Route path="home" element={<LandingHome />} />{" "}
                {/* <LandingHome/> */}
                <Route path="brief" element={<Brief />} />
                <Route
                  path="meta-data"
                  element={<LandingMetaData menu={2} />}
                />
                {creatorRouter &&
                  creatorRouter.map((element, index) => (
                    <Route
                      key={index}
                      path={`${element.url}`}
                      element={<Creator view={element.id} />}
                    />
                  ))}
                <Route path="image/:id" element={<ImageDetails />} />
                {/*    <Route path='lnd-about' element={<Creator view={2} />}/> 
                    <Route path='lnd-about/detail/:id' element={<DetailCreator view={2} menu={28}/>}/>

                    <Route path='lnd-store' element={<Creator view={1} />}/> 
                    <Route path='lnd-store/detail/:id' element={<DetailCreator view={1} menu={27}/>}/> */}
                <Route path="politics" element={<Politics />} />
              </Route>

              <Route
                path="/catalog"
                element={
                  <MenuDrawer
                    mode={mode}
                    changeMode={changeMode}
                    reload={reload}
                  />
                }
              >
                <Route path="home/*" element={<CatalogArticles />} />{" "}
                {/* <CatalogHome/> */}
                <Route path="articles/*" element={<CatalogArticles />} />
                <Route
                  path="articles/detail/:id"
                  element={<CatalogArticleDetail setReload={setReload} />}
                />
                <Route
                  path="articles/filter/:id"
                  element={<CatalogFilter setReload={setReload} />}
                />
              </Route>

              <Route
                path="/chat"
                element={
                  <MenuDrawer
                    mode={mode}
                    changeMode={changeMode}
                    reload={reload}
                  />
                }
              >
                <Route path="" element={<Chat />} />
              </Route>

              <Route
                path="/online-store"
                element={
                  <MenuDrawer
                    mode={mode}
                    changeMode={changeMode}
                    reload={reload}
                  />
                }
              >
                <Route path="home/*" element={<StoreOrders />} />{" "}
                {/* <StoreHome/> */}
                <Route path="orders/" element={<StoreOrders />} />
                <Route
                  path="orders/detail/:id"
                  element={<StoreOrderDetail />}
                />
                <Route path="pos" element={<StorePos />} />
                <Route path="customers" element={<StoreCustomers />} />
                <Route
                  path="products"
                  element={<StoreProducts view="store" />}
                />
                {/* landing */}
                {/*                   <Route path='store-info' element={<StoreInfo/>}/> 
              
                  <Route path='keski-creator' element={<Creator view={2} />}/>}
                  <Route path='keski-creator/detail/:id' element={<DetailCreator view={2} menu={26}/>}/> */}
                {/* settings */}
                <Route path="store-status" element={<StoreStatus />} />
                <Route path="operation-rules" element={<OperationRules />} />
                <Route path="shipments" element={<StoreShipments />} />
                <Route path="payments" element={<StorePayments />} />
                <Route path="general" element={<GeneralSettings />} />
              </Route>

              <Route
                path="/inventory"
                element={
                  <MenuDrawer
                    mode={mode}
                    changeMode={changeMode}
                    reload={reload}
                  />
                }
              >
                <Route path="home/*" element={<InventoryInventory />} />{" "}
                {/* <InventoryHome/> */}
                <Route path="warehouse/*" element={<InventarioWarehouse />} />
                <Route
                  path="warehouse/administration/*"
                  element={<AdminWareHouse />}
                />
                <Route
                  path="warehouse/purchase/detail/:id"
                  element={<InventoryWHPurchaseDetail setReload={setReload} />}
                />
                <Route
                  path="warehouse/sale/detail/:id"
                  element={<InventoryWHSaleDetail setReload={setReload} />}
                />
                <Route
                  path="warehouse/production/detail/:id"
                  element={<DetailProduction setReload={setReload} />}
                />
                <Route
                  path="warehouse/transfer/detail/:id"
                  element={<DetailTransfer setReload={setReload} />}
                />
                <Route path="suppliers/*" element={<InventorySuppliers />} />
                <Route
                  path="suppliers/detail/:id"
                  element={<SuppliersDetails />}
                />
                <Route
                  path="suppliers/detail/articles/:id"
                  element={<SuppliersArticles />}
                />
                <Route path="customers/*" element={<InventoryCustomer />} />
                <Route
                  path="customers/detail/:id"
                  element={<CustomerDetails />}
                />
                <Route path="purchases/*" element={<InventoryPurchase />} />
                <Route
                  path="purchases/detail/:id"
                  element={<PurchasesDetails setReload={setReload} />}
                />
                <Route path="sales/*" element={<InventorySales />} />
                <Route
                  path="sales/detail/:id"
                  element={<SalesDetails setReload={setReload} />}
                />
                <Route path="inventory/*" element={<InventoryInventory />} />
                <Route
                  path="inventory/scrap/:id"
                  element={<ScrapDetails setReload={setReload} />}
                />
                <Route
                  path="inventory/adjustment/:id"
                  element={<AdjustmentDetails setReload={setReload} />}
                />
                <Route
                  path="inventory/transfer/:id"
                  element={<TransferDetails setReload={setReload} />}
                />
                <Route
                  path="transformation/*"
                  element={<InventoryTransformation />}
                />
                <Route
                  path="transformation/detail/:id"
                  element={<TransformationDetails setReload={setReload} />}
                />
                <Route path="recipes/*" element={<InventoryRecipes />} />
                <Route
                  path="recipes/detail/:id"
                  element={<InventoryRecipesDetail />}
                />
                <Route path="settings" element={<InventorySettings />} />
              </Route>
              <Route
                path="/pos-admin"
                element={<MenuDrawer mode={mode} changeMode={changeMode} />}
              >
                <Route path="home" element={<PsAdmin />} />
                <Route path="cash-register/*" element={<CashRegister />} />
                <Route
                  path="cash-register/detail/:id"
                  element={<CashRegisterDetail setReload={setReload} />}
                />
                <Route path="settings" element={<PsAdminSettings />} />
                <Route path="discounts/*" element={<Discounts />} />
                <Route path="products" element={<StoreProducts view="pos" />} />
              </Route>

              {/* POSIntegration This section has been added to have the pos integrated in the admin project */}
              <Route
                exact
                path={"/pos/pointofsale/"}
                element={
                  <MenuDrawer
                    mode={mode}
                    changeMode={changeMode}
                    reload={reload}
                    openPurchaseList={openPurchaseList}
                    setOpenPurchaseList={setOpenPurchaseList}
                    posDrawer={"false"}
                  />
                }
              >
                <Route
                  path="cash-register/:cr/warehouse/:id/:name"
                  element={
                    <PosDrawer
                      listUpdate={listUpdate}
                      openPurchaseList={openPurchaseList}
                    />
                  }
                  mode={mode}
                  changeMode={changeMode}
                >
                  <Route
                    path={""}
                    element={
                      <PosHome
                        updateSalesList={updateSalesList}
                        openPurchaseList={openPurchaseList}
                      />
                    }
                  />
                </Route>
                <Route path="payment/sale/:id/" element={<PayProcess />} />
              </Route>

              {/* POSIntegration This section has been added to have the pos integrated in the admin project */}

              <Route path="/order/*" element={<ViewOrder />} />

              <Route path={"/*"} element={<Navigate to="/home" />} />
            </Routes>
          )}
          {!auth && loading === true && (
            <Routes>
              <Route path="/*" element={<LoadPage />} />
            </Routes>
          )}

          {!auth && loading === false && (
            <Routes>
              <Route path="/" element={<Login theme={mode} />} />
              <Route
                path="/recover-password"
                element={<RecoverPassword theme={mode} />}
              />
              <Route
                path="/reset-password"
                element={<ResetPassword theme={mode} />}
              />
              <Route path="/*" element={<Login theme={mode} />} />
              <Route
                path="/search-business"
                element={<SearchBusiness theme={mode} />}
              />
              <Route path="/order/*" element={<ViewOrder />} />
            </Routes>
          )}
        </div>
      </BrowserRouter>
    </context.AuthContext.Provider>
  );
}

export default Rutes;
