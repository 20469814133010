import React, { useState } from "react";
import { Grid, Typography, Link } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import * as Yup from "yup";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import GenericTable from "../../../../components/GenericTable";
import CircleIcon from "@mui/icons-material/Circle";
import { GenericEmptyTable } from "../../../../components/GenericEmptyTable";
import ImageSize from "../../../../components/ImageSize";
import { RecipeGroup } from "../../../../api/recipeManages";
import { useNotification } from "../../../../hooks/snackBarResponse";
import { useAuth } from "user-context-fran-dev";

export const SearchRecipe = ({ setOpen, setRecipe, updata }) => {
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState(null);
  const [msg, sendNotification] = useNotification();
  const [noFind, setFind] = useState(null);
  const { logout, auth } = useAuth();

  const SendInfo = (data) => {
    setOpen(true);
    setRecipe(data);
  };

  const formik = useFormik({
    initialValues: { name: "" },
    validationSchema: Yup.object({
      name: Yup.string().required("El campo es requerido"),
    }),
    onSubmit: async ({ resetForm }) => {
      setLoading(true);

      getRecipes(formik.values.name);
      resetForm();
      setLoading(false);
    },
  });

  const getRecipes = (name) => {
    (async () => {
      let irequestFilter = [];
      irequestFilter.push({ key: "pattern", value: `${name}` });
      irequestFilter.push({ key: "is_active", value: `true` });

      let getRecipe = new RecipeGroup();
      let response = await getRecipe.filter(irequestFilter);
      setTable(response.data);
      /* console.log(response) */
      if (Number(response.status) < 300) {
        //setOpenModal(false);
      } else {
        /* sendNotification(UseerrorOrsuccess(response)) */
      }
      setFind(name);
    })();
  };

  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item xs={6}>
              <TextField
                fullWidth
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                label="Buscar por nombre"
                size="small"
                variant="outlined"
                autoComplete="none"
                helperText={formik.touched.name ? formik.errors.name : ""}
              />
            </Grid>
            <Grid item xs={2}>
              <LoadingButton
                loading={loading}
                fullWidth
                type="submit"
                variant="contained"
              >
                Buscar
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>

      {table && (
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          mt={1}
        >
          <Grid item xs={12}>
            <GenericTable
              titles={[
                "Imagen",
                "SKU",
                "Nombre",
                "Estatus",
                "Categoría",
                "Acción",
              ]}
            >
              {table.map((row, index) => (
                <RowRaw
                  row={row}
                  key={index}
                  doSomething={SendInfo}
                  setOpen={setOpen}
                />
              ))}
            </GenericTable>
          </Grid>
        </Grid>
      )}
      {table != null && !table && (
        <Grid item sx={12}>
          <GenericEmptyTable
            msg={`No se encontraron resultados para  "${noFind}"`}
          />
        </Grid>
      )}
    </Grid>
  );
};
const RowRaw = ({ row, doSomething, setOpen }) => {
  const navigate = useNavigate();
  /* console.log(row) */

  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        hover
      >
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <ImageSize
            img={
              row.product.main_model?.image
                ? row.product.main_model.image.url
                : ""
            }
            weight={1}
          />
          {/*           <img
                src={row.product.main_model?.image? row.product.main_model.image.url: ""}
                style={{ width: "80%", height: "auto" }}
              ></img> */}
        </TableCell>
        <TableCell align="left" sx={{ maxWidth: "120px" }}>
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.product.sku}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ maxWidth: "120px" }}>
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.product.name}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ maxWidth: "120px" }}>
          <Typography variant="subtitle2" component="div">
            {row.product.is_active ? (
              <CircleIcon sx={{ color: "green", fontSize: 15 }} />
            ) : (
              <CircleIcon sx={{ color: "red", fontSize: 15 }} />
            )}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ maxWidth: "120px" }}>
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.product.category.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Link
            sx={{ cursor: "pointer" }}
            underline="none"
            onClick={() => {
              setOpen(true);
              doSomething(row);
            }}
          >
            <Typography variant="subtitle1" component="div">
              ver
            </Typography>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};
