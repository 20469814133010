import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StoreOrdersManages, {
  StoreOrdersCancel,
  StoreQuotation,
} from "../../../api/store/storeOrders";
import { useAuth } from "user-context-fran-dev";
import { ButtonStatus, MessageClientStatus, Order } from "./order";
import Container from "../../../layout/gridContainer";
import { GenericEmptyTable } from "../../../components/GenericEmptyTable";
import {
  useNotification,
  UseerrorOrsuccess,
} from "../../../hooks/snackBarResponse";
import "../../../scss/store.scss";
import { DateFormat2, DateFormat } from "../../../components/DateFormat";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AlertBox from "../../../components/AlertBox/AlertBox";

const styleStatusOrder = {
  display: "flex",
  flexDirection: "column",
  minWidth: "150px",
  maxWidth: "30vw",
  flexGrow: 1,
  alignItems: "center",
  padding: "8px 0",
  height: "64px",
  borderRadius: "5px",
  justifyContent: "center",
};

const getStatusMapping = (status, date_confirm, cancel) => {
  //Objeto y diccionario de estados y color correspondiente que mapeara
  const statusMappings = {
    3: { breadcrumbs: ["Pedido", "Sin confirmar"], color: "inherit" },
    4: {
      breadcrumbs: ["Pedido", "Confirmado", "Sin enviar"],
      color: "inherit",
    },
    5: {
      breadcrumbs: ["Pedido", "Confirmado", "Enviado", "En tránsito"],
      color: "inherit",
    },
    6: {
      breadcrumbs: ["Pedido", "Confirmado", "Enviado", "Entregado"],
      color: "inherit",
    },
  };

  //Objeto y diccionario de estados y color correspondiente que mapeara en caso de cancelar y si es status null o notnull
  const cancelStatusMappings = {
    3: {
      1: {
        breadcrumbs: ["Pedido", "Cancelado", "Sin reembolsar"],
        color: "inherit",
      },
    },
    4: {
      1: {
        breadcrumbs: ["Pedido", "Confirmado", "Cancelado", "Sin reembolsar"],
        color: "inherit",
      },
    },
    7: {
      null: {
        breadcrumbs: ["Pedido", "Cancelado", "Sin reembolsar"],
        color: "inherit",
      },
      notNull: {
        breadcrumbs: ["Pedido", "Confirmado", "Cancelado", "Sin reembolsar"],
        color: "inherit",
      },
      refund: {
        breadcrumbs: ["Pedido", "Confirmado", "Cancelado", "Reembolsado"],
        color: "inherit",
      },
    },
  };

  if (status === 7 && cancel?.status === 2) {
    // if (status === 7 || cancel?.status === 2) {
    return cancelStatusMappings[7]["refund"];
    // Determina si date_confirm es null o no y selecciona el mapeo correspondiente
  } else if (status === 3 && cancel?.status === 1) {
    return cancelStatusMappings[status][cancel?.status];
  } else if (status === 4 && cancel?.status === 1) {
    return cancelStatusMappings[status][cancel?.status];
  } else if (status === 7) {
    const key = date_confirm === null ? "null" : "notNull";
    return cancelStatusMappings[7][key];
  } else {
    // Si el mapeo no está definido, devuelve un objeto con breadcrumbs vacíos y color "inherit"
    return statusMappings[status] || { breadcrumbs: [], color: "inherit" };
  }
};

//Objeto y diccionario que ayudara para ciertos estados cambiar su color
const colorMappings = {
  3: { "Sin confirmar": "red", "Sin reembolsar": "red" },
  4: { "Sin enviar": "red", "Sin reembolsar": "red" },
  5: { "En tránsito": "green" },
  6: { Entregado: "green" },
  7: { "Sin reembolsar": "red", Reembolsado: "green" },
};

export const MessageCanceledByClient = ({ userDetails }) => {
  return (
    <>
      {userDetails?.cancel?.canceled_by === 1 && userDetails?.status === 3 ? (
        <AlertBox
          text="El cliente ha cancelado la orden, para realizar el reembolso confirma en tu cuenta de CLIP que cuentas con el saldo 
          para cubrir el total de la orden. Al realizar el reembolso recibirás el estado Aprobado/Declinado y el cliente será notificado."
          color="255, 0, 0"
          colorIcon="255, 0, 0"
          colorText="95, 33, 32"
          borderRadius="1.5%"
        />
      ) : null}
    </>
  );
};

export const StoreOrderDetail = () => {
  const { id } = useParams();
  const { logout, auth } = useAuth();

  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [statusOrder, setStatusOrder] = useState(0);
  const [msg, sendNotification] = useNotification();

  const { status, date_confirm, cancel } = userDetails;
  const { breadcrumbs, color } = getStatusMapping(status, date_confirm, cancel);

  const orderDetailsRequest = () => {
    (async () => {
      setLoading(true);
      const orderDetail = new StoreOrdersManages();
      const response = await orderDetail.getAllByIdAuth(id, logout);
      setOrderDetails(response.data.cart?.cart_items);
      setUserDetails(response.data);
      setStatusOrder(response.data.cart?.status);
      setLoading(false);
    })();
  };

  const cancelOrder = (setL) => {
    (async () => {
      setL(true);
      const orderDetail = new StoreOrdersCancel();
      let response = await orderDetail.postDataAuth(
        { canceled_by: "store", order_id: id },
        logout
      );
      console.log(response.data);
      sendNotification(UseerrorOrsuccess(response));
      orderDetailsRequest();
      setL(false);
    })();
  };

  const NextStep = (next, setLoading) => {
    (async () => {
      setLoading(true);

      const orderDetail = new StoreOrdersManages();
      let response = await orderDetail.editDataAuth(id, next, logout);
      console.log(response.data);
      sendNotification(UseerrorOrsuccess(response));
      orderDetailsRequest();
      setLoading(false);
    })();
  };

  useEffect(() => {
    setLoading(true);
    orderDetailsRequest(setLoading);
    /*     document.title = "text" */
  }, []);

  return (
    <Container>
      <MessageCanceledByClient userDetails={userDetails} />
      <Grid
        container
        justifyContent="space-between"
        sx={{ gap: "8px" }}
        fontWeight="bold"
      >
        {/*-------------------ESTADOS DE LOS BREADCRUMBS----------------------*/}
        <Grid item sx={6}>
          <div role="presentation">
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              {breadcrumbs.map((label) => {
                // Para cada elemento en 'breadcrumbs', obtenemos el color correspondiente
                // desde 'colorMappings'. Si no se encuentra un color específico, usamos 'color'.
                const linkColor = colorMappings[status]?.[label] || color;
                return (
                  <Link
                    key={label}
                    underline="none"
                    color={linkColor}
                    sx={{ fontSize: 18 }}
                  >
                    {label}
                  </Link>
                );
              })}
            </Breadcrumbs>
          </div>
        </Grid>
        <Grid item sx={6}>
          <ButtonStatus
            userDetails={userDetails}
            orderDetails={orderDetails}
            statusOrder={statusOrder}
            cancelOrder={cancelOrder}
            NextStep={NextStep}
            update={orderDetailsRequest}
          />
        </Grid>

        {/*---------------------------------------------------------------------*/}
        {/*
          <Grid
            item
            sx={styleStatusOrder}
            backgroundColor={userDetails.status == 2 ? "primary.main" : userDetails.status > 2 ? "background.tabs" : ""}
          >
            <Typography variant="body1" display="div">
              <strong>Pedido</strong>
            </Typography>

            {userDetails.status > 2 && <Typography variant="body1" display="div">
              {userDetails?.date_processed && <DateFormat2 beginDate={userDetails.date_processed} />}
            </Typography>}
          </Grid>
          {userDetails.status == 7 && userDetails.date_confirmed == null &&
            <Grid
              item
              xs={3.8}
              md={2.3}
              sx={styleStatusOrder}
              backgroundColor={userDetails.status == 7 ? "primary.main" : "background.tabs"}
            >
              <Typography variant="body1" display="div">
                <strong>Cancelado</strong>
              </Typography>
              <Typography variant="body1" display="div">
                <DateFormat2 beginDate={userDetails.date_cancel} />

              </Typography>
            </Grid>}

          <Grid
            item
            sx={styleStatusOrder}
            backgroundColor={userDetails.status == 3 ? "primary.main" : userDetails.status > 3 ? "background.tabs" : ""}
          >
            <Typography variant="body1" display="div">
              <strong>Confirmado</strong>
            </Typography>
            {userDetails.status > 3 && <Typography variant="body1" display="div">


              {userDetails.date_confirmed && <DateFormat2 beginDate={userDetails?.date_confirmed} />}
            </Typography>}
          </Grid>

          {userDetails.status == 7 && userDetails.date_confirmed !== null && userDetails.date_shipping == null &&
            <Grid
              item
              xs={3.8}
              md={2.3}
              sx={styleStatusOrder}
              backgroundColor={userDetails.status == 7 ? "primary.main" : "background.tabs"}
            >
              <Typography variant="body1" display="div">
                <strong>Cancelado</strong>
              </Typography>
              <Typography variant="body1" display="div">

                <DateFormat2 beginDate={userDetails.date_cancel} />
              </Typography>
            </Grid>}


          <Grid
            item
            sx={styleStatusOrder}

            backgroundColor={userDetails.status == 4 ? "primary.main" : userDetails.status > 4 ? "background.tabs" : ""}
          >
            <Typography variant="body1" display="div">
              <strong>Enviar</strong>
            </Typography>
            {userDetails.status > 4 && userDetails.date_shipping && <Typography variant="body1" display="div">

              <DateFormat2 beginDate={userDetails.date_shipping} />

            </Typography>}
          </Grid>
          {userDetails.status !== 7 &&
            <Grid
              item sx={styleStatusOrder}
              backgroundColor={userDetails.status == 5 ? "primary.main" : userDetails.status > 5 ? "background.tabs" : ""}
            >
              <Typography variant="body1" display="div">
                <strong>Por entregar</strong>
              </Typography>
              {userDetails.status >= 5 && userDetails?.date_delivery && <Typography variant="body1" display="div">
                <DateFormat2 beginDate={userDetails.date_delivery} />
              </Typography>}
            </Grid>}
            */}
      </Grid>

      {statusOrder === 0 || statusOrder > 5 ? (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={6}>
            <GenericEmptyTable
              msg={"¡La Orden a la que intenta acceder no existe!"}
            />
          </Grid>
        </Grid>
      ) : (
        <>
          <Order
            userDetails={userDetails}
            orderDetails={orderDetails}
            statusOrder={statusOrder}
            cancelOrder={cancelOrder}
            NextStep={NextStep}
            update={orderDetailsRequest}
          />
        </>
      )}
    </Container>
  );
};
