import React, { useState } from "react";
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Menu from '@mui/material/Menu';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'user-context-fran-dev';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import KeyIcon from '@mui/icons-material/Key';
import Logout from '@mui/icons-material/Logout';
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { CardHeader, MenuItem } from '@mui/material';
import { Iniciales } from "../utils/functions";
import { useLocation } from 'react-router-dom';


const GenericUserMenu = ({ changeMode, mode, color }) => {
  const Navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { logout, auth, client } = useAuth();

  const location = useLocation();
  const isHome = location.pathname === '/home';


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };
  const onclickLogOut = () => {
    logout();
    Navigate("/");

  };
  const onclickChangesPassword = () => {

    Navigate("/changespassword");

  };


  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        sx={{ color: isHome ? 'black' : 'white' }}
      //sx={{ mb: 0, color: color ?color[mode] : "#000"  }}

      >
        <AccountCircleOutlinedIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        /* onClick={handleClose} */
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <Avatar aria-label="recipe">
            {Iniciales(auth.user.first_name, auth.user.last_name)}
          </Avatar>
          <CardHeader type="datetime-local"
            sx={{ m: 0, p: 0 }}
            title={
              <Typography variant="subtitle2" sx={{ m: 0, p: 0, }} component="div">
                {`${auth.user.first_name} ${auth.user.last_name}`}
              </Typography>
            }
            subheader={
              auth.user.email
            }></CardHeader>
        </MenuItem>
        <Divider />
        {/* <MenuItem onClick={() => changeMode()}>
            <ListItemIcon>
            {mode === "dark" ? <Brightness7Icon sx={{color: 'text.secondary'}}/> : <Brightness4Icon sx={{color: 'text.secondary'}} />}
            </ListItemIcon>
            Tema {mode} 
          </MenuItem> */}
        {/*           <MenuItem onClick={()=> onclickChangesPassword()}>
            <ListItemIcon>
              <KeyIcon fontSize="small" sx={{color: 'text.secondary'}}/>
            </ListItemIcon>
            Cambiar contraseña
          </MenuItem> */}
        <MenuItem onClick={() => onclickLogOut()}>
          <ListItemIcon>
            <Logout fontSize="small" sx={{ color: 'text.secondary' }} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default GenericUserMenu;