import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Paper,
  Box,
  Button,
  styled,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Menu,
  Switch,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import "../../../scss/catalog.scss";
import EditorConvertToHTML from "../../../components/RichText";
import CategoryManages from "../../../api/categoryManages";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValDataArticle,
  initValAddCategory,
  valSchDataArticle,
} from "../../../utils/formHelper";
import Products from "../../../api/productsManages";
import { useAuth } from "user-context-fran-dev";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import InfoIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { pointer } from "@testing-library/user-event/dist/cjs/pointer/index.js";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  margin: "0 0 24px",
  width: "100%",
}));
const scroll = {
  overflowY: "scroll",
  maxHeight: "300px",
  scrollbarWidth: "thin",
};

const ArticleData = ({ article, getArticleInfo, load }) => {
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const [selectedValue, setSelectedValue] = useState(3);
  const prop_path = ["cost", "price_store", "price_discount_store", "bar_code"];

  const SetProductInfo = (formData) => {
    (async () => {
      let setProduct = new Products();
      let response = await setProduct.editDataAuth(
        article.id,
        formData,
        logout
      );

      if (response) {
        sendNotification(UseerrorOrsuccess(response));
      } else {
        sendNotification(
          UseerrorOrsuccess({ msg: "Error al actualizar", variant: "error" })
        );
      }
      getArticleInfo();
    })();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography fontSize={"24px"} margin={"24px 0 40px"}>
        <strong>Producto</strong>
      </Typography>
      <Grid
        container
        spacing={{ xs: 0, sm: 0, md: 3 }}
        display={"flex"}
        flexDirection={{
          md: "row",
          sm: "column-reverse",
          xs: "column-reverse",
        }}
      >
        {/* -------------Left Grid ------------------>*/}
        <Grid item xs={12} sm={12} md={8}>
          <ArticleInfo
            article={article}
            SetProductInfo={SetProductInfo}
            load={load}
          />
          <Card title="Tipo de producto" height={150}>
            <ArticleType
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
            />
          </Card>
          <>
            {selectedValue === 3 && (
              <Simple
                productImage={
                  <ArticleGallery article={article?.main_model?.image?.url} />
                }
                priceContent={
                  <PriceForm article={article} prop_path={prop_path} />
                }
              />
            )}
            {selectedValue === 2 && (
              <Model
                article={article}
                productImage={<ArticleGallery />}
                state={<ArticleState article={article} model={"model"} />}
                priceContent={<PriceForm />}
              />
            )}
            {selectedValue === 1 && (
              <Model
                article={article}
                productImage={<ArticleGallery />}
                state={<ArticleState article={article} model={"model"} />}
                priceContent={<PriceForm />}
                variant={true}
              />
            )}
          </>
        </Grid>
        {/*--------------------- right grid -------------------->*/}
        <Grid item xs={12} sm={12} md={4}>
          <Card title={"Estado"}>
            <ArticleState article={article} />
          </Card>
          <Card title={"Mostrar producto"}>
            <ArticleShowIn />
          </Card>
          <Card p="8px 16px" title={"Categorías"} style={scroll}>
            <ArticleCategory
              SetProductInfo={SetProductInfo}
              article={article}
              logout={logout}
            />
          </Card>
          <Card title={"Filtros"} style={scroll}>
            <ArticleFilters />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
// ---------Sub Components------------------------------------------------>
const ArticleCategory = (props) => {
  const manageCategory = new CategoryManages();
  const [selectedOption, setSelectedOption] = useState("");
  const [showInput, setShowInput] = useState();
  const [table, setTable] = useState([]);
  const [upDate, setUpDate] = useState(false);

  useEffect(() => {
    if (props.article) {
      UpdateTable();
      setSelectedOption(props.article.category?.id); // Añadido el encadenamiento opcional
    }
  }, [upDate, props.article]);

  const UpdateTable = () => {
    (async () => {
      let response = await manageCategory.getAllAuth(props.logout);
      console.log(response?.data);
      setTable(response?.data);
    })();
  };

  const formik = useFormik({
    initialValues: initValAddCategory(),
    enableReinitialize: true,
    validationSchema: false,
    onSubmit: async (formData) => {
      try {
        let response = await manageCategory.postDataAuth(
          formData,
          props.logout
        );
        console.log(response);
        setUpDate(true);
        formik.resetForm();
      } catch (error) {
        console.error("Error al enviar los datos", error);
      }
    },
  });

  const handleCheckBox = (option) => {
    setSelectedOption(option === selectedOption ? null : option);
    props.SetProductInfo({ category: option });
  };

  return (
    <>
      {table?.map((data, index) => (
        <Grid key={index} display={"flex"} alignItems={"center"}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => handleCheckBox(data.id)}
                checked={selectedOption === data.id}
              />
            }
          />
          <Typography>{data.name}</Typography>
        </Grid>
      ))}
      <Grid
        sx={{
          display: showInput ? "flex" : "none",
          alignItems: "center",
        }}
        component={"form"}
        onSubmit={formik.handleSubmit}
      >
        <FormControlLabel control={<Checkbox />} />
        <TextField
          size="small"
          margin="dense"
          label={"Agregar categoria"}
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          sx={{ mr: "8px" }}
        />
        <CloseIcon
          sx={{
            fontSize: 30,
            m: "0 8px",
            cursor: "pointer",
            color: "GrayText",
            ":hover": { color: "red" },
          }}
          onClick={() => setShowInput(false)}
        />
        <IconButton color="primary" type="submit">
          <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </Grid>
      <Grid display={"flex"} justifyContent={"flex-start"} p={"8px 0 "}>
        <Button onClick={() => setShowInput(true)} type="submit" variant="text">
          + Crear Categoría
        </Button>
      </Grid>
    </>
  );
};
const ArticleInfo = ({ article, SetProductInfo, load }) => {
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(null);

  useEffect(() => {
    window.localStorage.setItem("menu", 10);
  }, []);

  useEffect(() => {
    if (article?.description) {
      setDescription(article.description);
    } else if (article?.description === null) {
      setDescription("");
    }
  }, [article]);

  const handleOnClickDescription = (text) => {
    setDescription(text);
  };

  const formik = useFormik({
    initialValues: initValDataArticle(article),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchDataArticle()),
    onSubmit: async (formData) => {
      formik.values.description = description;
      SetProductInfo(formData, setLoading);
    },
  });

  return (
    <Item elevation={4} sx={{ p: "16px", height: "450px", marginBottom: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        {article && (
          <>
            {["name", "sku"].map((val) => (
              <>
                <TextField
                  fullWidth
                  id={val}
                  name={val}
                  value={formik.values[val]}
                  onChange={formik.handleChange}
                  error={formik.touched[val] && Boolean(formik.errors[val])}
                  label={val === "sku" ? "SKU" : "Nombre"}
                  size="small"
                  margin="normal"
                  helperText={formik.touched[val] ? formik.errors[val] : ""}
                />
              </>
            ))}

            <Paper
              elevation={2}
              sx={{ borderRadius: "10px 10px 0 0", mt: "24px" }}
            >
              <Typography variant="h6" fontSize={"18px"} p={"8px "}>
                Descripción
              </Typography>
            </Paper>
            <Paper
              elevation={2}
              sx={{
                backgroundColor: "#fff",
                height: "200px",
              }}
            >
              {description !== null && (
                <EditorConvertToHTML
                  handleClick={handleOnClickDescription}
                  data={description}
                />
              )}
            </Paper>
          </>
        )}
      </form>
    </Item>
  );
};
const ArticleFilters = ({ props }) => {
  const [expanded, setExpanded] = useState(false);
  const [showInput, setShowInput] = useState("");
  const handleAcordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false); // Abre el acordeón seleccionado o cierra si está ya abierto
  };
  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleAcordion("panel1")}
        sx={{
          boxShadow: "none", // Remove the box shadow
          border: "none", // Ensure there's no border
          "&:before": {
            display: "none", // Remove the default divider line
          },
        }}
      >
        <AccordionSummary sx={{ p: 0 }} expandIcon={<ExpandMoreIcon />}>
          <Typography>Género</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: "0 16px" }}>
          {["Adidas", "Lacoste"].map((data, index) => (
            <Stack key={index} direction="row" alignItems={"center"}>
              <FormControlLabel control={<Checkbox />} />
              <Typography>{data}</Typography>
            </Stack>
          ))}
          <Grid
            sx={{
              display: showInput === "genero" ? "flex" : "none",
              alignItems: "center",
            }}
            component={"form"}
            // onSubmit={formik.handleSubmit}
          >
            <FormControlLabel control={<Checkbox />} />
            <TextField
              size="small"
              margin="dense"
              label={"Agregar genero"}
              name="name"
            />
            <CloseIcon
              sx={{
                fontSize: 30,
                m: "0 8px",
                cursor: "pointer",
                color: "GrayText",
                ":hover": { color: "red" },
              }}
              onClick={() => setShowInput("")}
            />
            <IconButton color="primary" type="submit">
              <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30 }} />
            </IconButton>
          </Grid>
          <Button
            onClick={() => setShowInput("genero")}
            type="submit"
            variant="text"
            sx={{ pt: "16px" }}
          >
            + Añadir
          </Button>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleAcordion("panel2")}
        sx={{
          boxShadow: "none", // Remove the box shadow
          border: "none", // Ensure there's no border
          "&:before": {
            display: "none", // Remove the default divider line
          },
        }}
      >
        <AccordionSummary sx={{ p: 0 }} expandIcon={<ExpandMoreIcon />}>
          <Typography>Marcas</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: "0 16px" }}>
          {["Adidas", "Lacoste"].map((data, index) => (
            <Stack key={index} direction="row" alignItems={"center"}>
              <FormControlLabel control={<Checkbox />} />
              <Typography>{data}</Typography>
            </Stack>
          ))}
          <Grid
            display={"flex"}
            sx={{
              display: showInput === "marca2" ? "flex" : "none",
              alignItems: "center",
            }}
            component={"form"}
          >
            <FormControlLabel control={<Checkbox />} />
            <TextField
              size="small"
              margin="dense"
              label={"Agregar marca"}
              name="name"
            />
            <CloseIcon
              sx={{
                fontSize: 30,
                m: "0 8px",
                cursor: "pointer",
                color: "GrayText",
                ":hover": { color: "red" },
              }}
              onClick={() => setShowInput("")}
            />
            <IconButton color="primary" type="submit">
              <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30 }} />
            </IconButton>
          </Grid>
          <Button
            onClick={() => setShowInput("marca2")}
            type="submit"
            variant="text"
          >
            + Añadir
          </Button>
        </AccordionDetails>
      </Accordion>
      <Grid
        display={"flex"}
        sx={{
          display: showInput === "filter" ? "flex" : "none",
          alignItems: "center",
        }}
        component={"form"}
        //onSubmit={formik.handleSubmit}
      >
        <TextField
          size="small"
          margin="dense"
          label={"Agregar filtro"}
          name="name"
          sx={{ mr: "16px" }}
        />
        <CloseIcon
          sx={{
            fontSize: 30,
            m: "0 8px",
            cursor: "pointer",
            color: "GrayText",
            ":hover": { color: "red" },
          }}
          onClick={() => setShowInput("")}
        />
        <IconButton color="primary" type="submit">
          <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </Grid>
      <Grid display={"flex"} justifyContent={"flex-start"}>
        <Button
          onClick={() => setShowInput("filter")}
          type="submit"
          variant="text"
          sx={{ p: "8px 0" }}
        >
          + Crear filtro
        </Button>
      </Grid>
    </>
  );
};
const ArticleGallery = (props) => {
  return (
    <Grid alignItems={"center"} display={"flex"} p={"16px"}>
      <div>
        <Paper
          elevation={3}
          sx={{ width: 160, height: 160, m: "16px", p: "8px" }}
        >
          <img
            src={props.article}
            alt="Lazy Load Image"
            loading="lazy"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
            }}
          />
        </Paper>
        <Typography p={"0 16px"}>Imagen del producto</Typography>
      </div>
      <AddBoxIcon sx={{ fontSize: 60, cursor: "pointer", color: "gray" }} />
    </Grid>
  );
};
const ArticleState = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        <RemoveRedEyeOutlinedIcon />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Typography mr={2}>Visibilidad: </Typography>
          <Typography>
            <strong>{props.article.is_active ? "Publicado" : "Privado"}</strong>
          </Typography>
        </Box>

        <Button
          variant="text"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{ textTransform: "none", fontSize: "16px" }}
        >
          Editar
        </Button>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>
            <div style={{ display: "flex" }}>
              <RemoveRedEyeOutlinedIcon sx={{ mr: 2 }} />
              <Typography>Visible</Typography>
            </div>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <div style={{ display: "flex" }}>
              <VisibilityOffOutlinedIcon sx={{ mr: 2 }} />
              <Typography>Ocultar</Typography>
            </div>
          </MenuItem>
        </Menu>
      </Stack>{" "}
      <Stack
        alignItems={"center"}
        direction={"row"}
        spacing={2}
        display={props.model ? "none" : ""}
      >
        <CalendarMonthOutlinedIcon />
        <Typography p={"8px 0"}>
          Publicado: <b style={{ padding: "0 16px" }}>Jul 7, 2024</b>
        </Typography>
      </Stack>
    </>
  );
};
const ArticleShowIn = (props) => {
  return (
    <>
      {["Tienda en línea / Catálogo", "Punto de venta"].map((data, index) => (
        <Stack
          key={index}
          direction="row"
          alignItems={"center"}
          spacing={props.spacing}
        >
          <FormControlLabel control={<Checkbox />} />
          <Typography>{data}</Typography>
        </Stack>
      ))}
    </>
  );
};
const ArticleType = (props) => {
  const productTypes = ["Simple", "Con modelos", "Con modelos y variantes"];

  const handleChange = (event) => {
    props.setSelectedValue(event.target.value); // Update the state with the selected value
  };
  return (
    <>
      <Typography p={"8px 0"}>Mostrar producto:</Typography>
      <TextField
        size="small"
        margin="normal"
        label={"Tipo de producto"}
        select
        value={props.selectedValue}
        onChange={handleChange}
        sx={{ width: "40%" }}
      >
        {productTypes.map((val, index) => (
          <MenuItem key={index} value={3 - index}>
            <Typography>{val}</Typography>
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};
const PriceForm = (props) => {
  return (
    <Grid container spacing={2}>
      {["Costo", "Precio", "Descuento", "Código de barras"].map(
        (val, index) => (
          <Grid item xs={6} md={6} sm={12} key={index}>
            <TextField
              fullWidth
              label={val}
              size="small"
              margin="normal"
              value={props.article?.[props.prop_path[index]]}
              slotProps={{
                input: {
                  endAdornment:
                    val === "Descuento" ? (
                      <InputAdornment position="end">%</InputAdornment>
                    ) : (
                      <></>
                    ),
                  startAdornment:
                    val !== "Código de barras" && val !== "Descuento" ? (
                      <InputAdornment position="start">$</InputAdornment>
                    ) : (
                      <></>
                    ),
                },
              }}
            />
          </Grid>
        )
      )}
      <Box display={"flex"} flexDirection={"column"}>
        <Stack direction="row" sx={{ alignItems: "center", p: "8px 16px" }}>
          <Typography>Oferta</Typography>
          <Switch defaultChecked inputProps={{ "aria-label": "ant design" }} />
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          sx={{ alignItems: "center", p: "0px 16px" }}
        >
          <Typography>El descuento aplica:</Typography>
          <ArticleShowIn spacing={-0.5} />
        </Stack>
      </Box>
    </Grid>
  );
};
const Simple = (props) => {
  return (
    <>
      <Card p title={"Imagen del producto"}>
        {props.productImage}
      </Card>
      <Card title={"Precio"}>{props.priceContent}</Card>
    </>
  );
};
const Model = (props) => {
  const prop_path = ["cost", "price", "price_discount", "bar_code"];
  const [showInput, setShowInput] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [editVariant, setEditVariant] = useState(false);
  const [variant, setVariant] = useState();

  const handleAcordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false); // Abre el acordeón seleccionado o cierra si está ya abierto
  };

  return (
    <>
      <Card p={"0px"} title={"Modelos"}>
        {props.article.model.map((data, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleAcordion(index)}
            sx={{
              "&.MuiAccordion-root": {
                boxShadow: "none", // Elimina la sombra
                borderBottom: "1px solid #ccc", // Solo muestra el borde inferior
              },
              "&.Mui-expanded": {
                margin: "0", // Elimina el espacio adicional al expandirse
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography p={""}>{data.name}</Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails sx={{ p: "8px 16px", bgcolor: "#fbfbfb" }}>
              {React.cloneElement(props.productImage, {
                article: data.images[0].url,
              })}
              <Divider />
              <Grid
                p={" 8px 0"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                {props.state}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Stack direction="row" sx={{ alignItems: "center" }}>
                    <Typography>Imagen principal</Typography>
                    <Switch
                      defaultChecked
                      inputProps={{ "aria-label": "ant design" }}
                    />
                  </Stack>
                  <Tooltip
                    title="Elige un modelo para establecer como imagen principal en tu catálogo o tienda en línea"
                    placement="top"
                  >
                    <InfoIcon color="primary" sx={{ cursor: "pointer" }} />
                  </Tooltip>
                </Box>
              </Grid>
              <Divider />
              <Box display={props.variant ? "" : "none"}>
                <Grid p={" 16px 0"} display={"flex"} alignItems={"center"}>
                  <TextField
                    label={"Selecciona una variante"}
                    variant="outlined"
                    select
                    size="small"
                    value={variant}
                    sx={{ width: "50%" }}
                    onChange={(e) => setVariant(e)}
                  >
                    {data.presentation.map((data) => (
                      <MenuItem key={data.id} value={data.id}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <Button
                    variant="text"
                    onClick={() => setShowInput(true)}
                    sx={{ m: "0 8px" }}
                  >
                    + Añadir variante
                  </Button>
                </Grid>
                <div
                  style={{
                    display: showInput ? "flex" : "none",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <TextField
                    label={"Agregar variante"}
                    variant="outlined"
                    size="small"
                    sx={{
                      width: "50%",
                    }}
                  />
                  <CloseIcon
                    sx={{
                      fontSize: 30,
                      m: "0 8px",
                      cursor: "pointer",
                      color: "GrayText",
                      ":hover": { color: "red" },
                    }}
                    onClick={() => setShowInput(false)}
                  />
                  <IconButton color="primary" type="submit">
                    <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30 }} />
                  </IconButton>
                </div>
                <Divider />

                <Stack
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  direction={"row"}
                  p={"8px 0"}
                >
                  <Grid display={"flex"} alignItems={"center"} mr={8}>
                    <Typography fontSize={"18px"}>
                      <b>Variante</b>
                    </Typography>

                    {!editVariant ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography fontSize={"18px"} p={"0 16px"}>
                          {data.presentation[0].name}
                        </Typography>
                        <EditOutlinedIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => setEditVariant(true)}
                        />
                      </div>
                    ) : (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <TextField
                          size="small"
                          value={data.presentation[0].name}
                          sx={{ p: "0 16px" }}
                        />
                        <CloseIcon
                          sx={{
                            fontSize: 30,
                            m: "0 8px",
                            cursor: "pointer",
                            color: "GrayText",
                            ":hover": { color: "red" },
                          }}
                          onClick={() => setEditVariant(false)}
                        />
                        <IconButton color="primary" type="submit">
                          <CheckCircleOutlineOutlinedIcon
                            sx={{ fontSize: 30 }}
                          />
                        </IconButton>
                      </div>
                    )}
                  </Grid>
                  {props.state}
                  <></>
                </Stack>
              </Box>
              <Grid p={" 8px 0"}>
                {React.cloneElement(props.priceContent, {
                  article: data.presentation[0],
                  prop_path: prop_path,
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Card>
    </>
  );
};
export default ArticleData;

export function Card(props) {
  return (
    <Item elevation={4}>
      <Grid sx={{ bgcolor: "#f7f7f7" }}>
        <Typography p={"16px"}>
          <strong>{props.title}</strong>
        </Typography>
      </Grid>
      <Divider />
      <Grid
        p={props.p ? props.p : "16px"}
        sx={props.style}
        height={props.height}
      >
        {props.children}
      </Grid>
    </Item>
  );
}
