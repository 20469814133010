import React, { useState, useEffect } from "react";
import GenericTable from "../../components/GenericTable";
import { Button, Grid, Typography } from "@mui/material";
import Container from "../../layout/gridContainer";
import ClientTable from "./customer/customerTable";
import AddCustomer from "./customer/addCustomer";
import CustomerService from "../../api/customerService";
import { useAuth } from "user-context-fran-dev";
import { PaginationGeneric } from "react-generic-pagination";
import { useNavigate } from "react-router-dom";
import {
  GenericEmptyTable,
  GenericSkeletonTable,
} from "../../components/GenericEmptyTable";
import CircularProgress from "@mui/material/CircularProgress";
import GenericSearch from "../../components/GenericSearch/GenericSearch";

const InventoryCustomer = (props) => {
  const [openModal, setOpenModal] = useState();
  const [category, setCategory] = useState("");
  const { logout } = useAuth();
  const [table, setTable] = useState(null);
  const [page, setPage] = useState(1); //primer request en page 1
  const [totalpages, setTotalPages] = useState("");
  const [loading, setLoading] = useState(true);
  const totalPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`page=${page}`);
    UpdateSupplierList();
  }, [page]);

  const UpdateSupplierList = () => {
    (async () => {
      setLoading(true);
      let irequestFilter = [];
      irequestFilter.push({ key: "page", value: `${page}` });
      irequestFilter.push({ key: "items", value: `${totalPerPage}` });
      let getCustomers = new CustomerService();
      let response = await getCustomers.filter(irequestFilter, logout);
      setTotalPages(response.data.pagination);
      setTable(response.data.data);
      setLoading(false);
    })();
  };

  const pageSetter = (page) => {
    setPage(page);
    navigate(`page=${page}`);
  };

  return (
    <Container>
      <Grid container spacing={2}>
        {/*       <Grid item xs={12}>
        <Typography variant="h5" gutterBottom component="div">
          <strong>Proveedores </strong>
        </Typography>
      </Grid> */}
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            className="title-card"
            sx={{ paddingBottom: "2%" }}
          >
            <strong>Clientes</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <GenericSearch
                Service={CustomerService}
                setData={setTable}
                label={"Buscar por Nombre"}
              ></GenericSearch>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={() => setOpenModal(true)}
                    fullWidth
                    variant="contained"
                  >
                    {" "}
                    Agregar Cliente{" "}
                  </Button>
                  <AddCustomer
                    openModal={openModal}
                    UpdateSupplierList={UpdateSupplierList}
                    setOpenModal={setOpenModal}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <>
            <GenericTable titles={["Compañia", "Contacto", "Acciones"]}>
              {loading && <GenericSkeletonTable />}
              {!loading && !table && <GenericEmptyTable />}
              {!loading &&
                table &&
                table.map((data, index) => (
                  <ClientTable key={index} data={data} />
                ))}
            </GenericTable>
            <div className="margin">
              <PaginationGeneric
                className="paginationStyle"
                pagina={1}
                total={totalpages}
                pageSetter={pageSetter}
              ></PaginationGeneric>
            </div>
          </>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InventoryCustomer;
