import React, { useState } from "react";
import { Button, Paper, Typography } from "@mui/material";
import { TextField, Grid } from "@mui/material";
import "../scss/adminBusiness.scss";
import Link from "@mui/material/Link";
import { GenericAddressDrawer } from "./GenericAddAddress";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValBusinessAddressInfo,
  valSchBusinessAddress,
} from "../utils/formHelper";
import EditIcon from "@mui/icons-material/Edit";
import { GenericDrawer } from "./keskiCreator/genericDrawer";

const GenericAddress = ({ data, UpdateInfo, target }) => {
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState({
    top: false,
    top1: false,
    right: false,
    right1: false,
  });

  const toggleDrawer = (anchor, open) => {
    // console.log("Toggling drawer", anchor, open);
    setOpenDrawer((prevState) => ({
      ...prevState,
      [anchor]: open, // Esto actualizará solo la clave correspondiente
    }));
  };
  const formik = useFormik({
    initialValues: initValBusinessAddressInfo(data, true),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchBusinessAddress()),
    onSubmit: async (formData) => { },
  });

  console.log("Datos de orden direccion", data);

  return (
    <Paper className="paper">
      <Grid
        container
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            className="title-card"
          >
            <strong>Dirección principal</strong>
          </Typography>
        </Grid>
        <Grid>
          <Button
            onClick={() => toggleDrawer("right", true)}
            variant="text"
            startIcon={<EditIcon />}
          >
            Editar
          </Button>
        </Grid>
      </Grid>
      <GenericAddressDrawer
        data={data}
        openDrawer={openDrawer}
        toggleDrawer={toggleDrawer}
        UpdateInfo={UpdateInfo}
        target={target}
        close={() => toggleDrawer("right", false)}
      />
      <Grid container spacing="16px" mt="16px">
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="street"
            label="Dirección (Calle, torre, empresa)"
            size="small"
            value={formik.values.street}
            onChange={formik.handleChange}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="ext_number"
            label="No. Exterior"
            size="small"
            value={formik.values.ext_number}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="int_number"
            label="No. Interior"
            size="small"
            value={formik.values.int_number}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="postal_code"
            label="Código Postal"
            size="small"
            value={formik.values.postal_code}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="state"
            label="Estado"
            size="small"
            value={formik.values.state}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="suburb"
            label="Colonia"
            size="small"
            value={formik.values.suburb}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="city"
            label="Ciudad/Municipio"
            size="small"
            value={formik.values.city}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="street_between"
            label="Calles con la que se cruza"
            size="small"
            value={formik.values.street_between}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="phone"
            label="Teléfono"
            size="small"
            value={formik.values.phone}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default GenericAddress;
