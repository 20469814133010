import React from "react";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useAuth } from "user-context-fran-dev";
import { UseerrorOrsuccess, useNotification } from "../hooks/snackBarResponse";
import { TransferItemsService } from "../api/inventory/transfersService";

const ITEM_HEIGHT = 48;

export default function DropMenu({
  service,
  id,
  update,
  handleEdit,
  setOpenEdit,
  setIdToEdit,
  row,
  toggleDrawer,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const openAria = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //console.log('id of item in order', id)

  const Edit = (row) => {
    //console.log('id of item in order', id)
    setIdToEdit(row);
    toggleDrawer("right1", true);
    /*  handleEdit(row) */
    handleClose();
  };

  const Delete = () => {
    (async () => {
      let serviceApi = new service /* TransferItemsService */();
      console.log("id of item in order", id);
      let response = await serviceApi.deleteAuth(id, logout);
      sendNotification(UseerrorOrsuccess(response));
      handleClose();
      update();
    })();
  };

  return (
    <>
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={openAria ? "long-menu" : undefined}
          aria-expanded={openAria ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={openAria}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              Delete();
            }}
          >
            Borrar
          </MenuItem>
          <MenuItem
            onClick={() => {
              Edit(row);
            }}
          >
            Editar
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}
