/*Aqui configuramos los fondos de background ya sea imagenes, color o degradado*/
import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    TextField,
    FormGroup,
    Switch,
} from "@mui/material";
import { useSnackbar } from "notistack";
import ImgEdit from "../landing/ImgEdit";
import CloseIcon from "@mui/icons-material/Close";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import { ImageCreator, ComponentCreator } from "../../api/landing/creatorManages";
import { useAuth } from "user-context-fran-dev";
import NoImageFound from "../../assets/no_found.png"
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValBackground, initValCreatorSection, valSchBackground } from "../../utils/formHelper";
import { LoadingButton } from "@mui/lab";
import { useNotification, UseerrorOrsuccess } from "../../hooks/snackBarResponse";
import Gallery from "./Gallery";
import Slider from '@mui/material/Slider';
import ToggleButtons from "./toggleButtons";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

const BgStyle = ({ bgStyle, data, UpdateSection, setOpenPreview }) => {
    const [color1, setColor1] = useState("#ffffff")
    const [color2, setColor2] = useState("#ffffff")
    const [gradiant, setGradiant] = useState("top")
    const [loading, setLoading] = useState(false)
    const [openGallery, setOpenGallery] = useState(false);
    const [select, setSelect] = useState();
    const [file, setFile] = useState();
    const [open, setOpen] = useState(false);
    const [newImg, setNewImg] = useState("")
    const { enqueueSnackbar } = useSnackbar();
    const { logout } = useAuth();
    const [msg, sendNotification] = useNotification();
    const [imgList, setImgList] = useState("");
    const [alignment, setAlignment] = useState('left');
    const [title, setTitle] = useState('');



    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const handleTitle = (e) => {
        //console.log(e.target.value)
        setTitle(e.target.value);

    };


    const Update = (file) => {
        (async () => {

            var formData = new FormData();
            formData.append('imgUpload', file);
            formData.append('type', "1");
            let get = new ImageCreator();

            let response = await get.uploadImg(formData, logout);
            sendNotification(UseerrorOrsuccess(response))

            UpdateImages()


        })();
    };

    const UpdateImages = async () => {
        let get = new ImageCreator();
        let response = await get.getAllAuth(logout);
        setImgList(response.data);
    }

    useEffect(() => {
        UpdateImages()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changeInput = (e) => {

        const files = e.currentTarget.files;

        console.log(files);
        console.log(URL.createObjectURL(files[0]));
        if (files.length <= 1) {
            setFile(files[0]); //URL.createObjectURL()
            console.log(URL.createObjectURL(files[0]));
            setOpen(true);
            console.log("open");
        } else {
            enqueueSnackbar("Maximo 1 imagen ", {
                autoHideDuration: 1000,
                variant: "warning",
            });
        }
    };

    const putData = async (formData) => {

        let put = new ComponentCreator();

        const response = await put.editDataAuth(data.component.id, formData, logout)
        sendNotification(UseerrorOrsuccess(response))

        UpdateSection(setLoading)

    };


    const formik = useFormik({
        initialValues: initValBackground(data),
        /* enableReinitialize: true, */
        validationSchema: Yup.object(valSchBackground()),
        onSubmit: async (formData, { resetForm }) => {
            setLoading(true);
            formData.justify_content = alignment;
            formData.title = title;
            console.log(formData)


            if (newImg) {
                formData.bg_image = newImg.id
            }


            console.log(formData)
            putData(formData)



        },
    });

    const setImage = (id) => {

        formik.values.bg_image = id
        /*    console.log(imgList.find((x)=>x.id== id)) */
        setNewImg(imgList.find((x) => x.id == id))
        setOpenGallery(false)

    };


    useEffect(() => {

        setColor1(data.component.bg_color1 || "#ffffff")

        setColor2(data.component.bg_color2 || "#ffffff")

        setGradiant(data.component.bg_gradiant_orientation || "#ffffff")

        setAlignment(data.component.justify_content || "left")

        setTitle(data.component.title || "")

        setNewImg(data.component.bg_image)

    }, [data])

    const sendColor = () => {
        setLoading(true)
        let formdata = {
            bg_color1: color1,
            bg_type: 1,
            justify_content: alignment,
            title: title
        }
        putData(formdata)
    }

    const sendGradiant = () => {
        setLoading(true)
        let formdata = {
            bg_color1: color1,
            bg_color2: color2,
            bg_gradiant_orientation: gradiant,
            bg_type: 2,
            justify_content: alignment,
            title: title
        }
        putData(formdata)
    }

    if (bgStyle === 3) {
        return (
            <form onSubmit={formik.handleSubmit}>
                <Grid container>
                    {file && open && (
                        <ImgEdit
                            open={open}
                            handleClose={setOpen}
                            file={file}
                            size={{ height: 720, width: 1280 }}  // TODO:  height: 360, width: 640   --- height: 720, width: 1280
                            update={Update}
                        />
                    )}
                    {openGallery && <Gallery open={openGallery} setOpen={setOpenGallery} setSelect={setSelect} select={select} title={"Selecciona una imagen"} setImage={setImage} />}
                    <Grid item xs={12} sx={{ margin: "16px 0px" }} >

                        <Box>
                            <label style={{}}>
                                <Input
                                    hidden
                                    sx={{ display: "none" }}
                                    type="file"
                                    multiple
                                    onChange={changeInput}
                                ></Input>
                                <Button

                                    component="span"
                                >
                                    Subir imagen
                                </Button>
                            </label>
                            <Button component="span" onClick={() => setOpenGallery(true)}>Seleccionar de galería</Button>
                        </Box>
                        {/*      {!newImg &&   <Typography variant="caption"  sx={{margin:"8px", color: "error.main"}}> 
           La imagen es requerida
          </Typography>} */}

                        {formik.touched.bg_image && Boolean(formik.errors.bg_image) && <Typography variant="caption" sx={{ margin: "8px", color: "error.main" }}>
                            {formik.touched.bg_image ? formik.errors.bg_image : ""}
                        </Typography>}
                        {/*         error={
                  formik.touched.text_color && Boolean(formik.errors.text_color)
                }
                helperText={
                  formik.touched.text_color ? formik.errors.text_color : ""
                }
   */}

                    </Grid>
                    {(data.component.bg_image || newImg) && <Grid item xs={12}>
                        <Card sx={{ maxWidth: "300px", }}>
                            <CardMedia
                                component="img"

                                height="100px"
                                image={newImg ? newImg.url : data.component.bg_image.url || NoImageFound}
                                alt={"none"}
                            />


                            <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => setOpenGallery(true)}
                                    startIcon={<ImageOutlinedIcon />}
                                    sx={{ borderRadius: '1em' }}
                                >
                                    Cambiar
                                </Button>
                                <DeleteOutlineIcon onClick={() => { putData({ bg_image: null }); setNewImg(""); formik.values.bg_image = "" }} sx={{ cursor: "pointer" }} />
                            </CardActions>
                        </Card>
                    </Grid>}
                    <Grid item xs={12} sx={{ margin: "16px 0" }}>
                        <FormGroup>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                                <Typography variant="body1" component="div">
                                    <strong>Background overlay</strong>
                                    <Typography variant="body2" component="div">
                                        Agregue una superposición de fondo par que el texto sea más fácil de leer.
                                    </Typography>
                                </Typography>
                                <Switch
                                    name="bg_image_overlay"
                                    checked={formik.values.bg_image_overlay}
                                    onChange={formik.handleChange}
                                    inputProps={{ "aria-label": "controlled" }}
                                />

                            </Box>

                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ display: "flex", alignItems: "center", marginTop: "16px" }}>Color:
                            <TextField
                                label="color"
                                size="small"
                                name="bg_image_overlay_color"
                                /*   disabled={!req["text_color"]} */

                                value={formik.values.bg_image_overlay_color || "#ffffff"}
                                sx={{ width: "100px", marginLeft: "8px" }}
                                onChange={formik.handleChange}
                                /*             
                                            error={
                                              formik.touched.text_color && Boolean(formik.errors.text_color)
                                            }
                                            helperText={
                                              formik.touched.text_color ? formik.errors.text_color : ""
                                            } */
                                type="color" />
                            {formik.values.bg_image_overlay_color}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ margin: "16px 0", display: "flex", flexDirection: "row" }}>
                        <Typography mr="16px">Cantidad</Typography>
                        <Box sx={{ display: "flex" }}>
                            <Slider
                                size="small"
                                sx={{ width: "150px" }}
                                name="bg_image_overlay_alpha"
                                value={formik.values.bg_image_overlay_alpha}
                                onChange={formik.handleChange}
                                valueLabelFormat={valueLabelFormat}
                                aria-label="Small"
                                valueLabelDisplay="auto"
                            />

                        </Box>
                        <Typography ml="16px">{valueLabelFormat(formik.values.bg_image_overlay_alpha)}</Typography>


                    </Grid>



                    <Grid item xs={12} sx={{ marginTop: "16px" }}>
                        <ToggleButtons handleAlignment={handleAlignment} alignment={alignment} handleTitle={handleTitle} title={title} data={data} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", width: "100%", justifyContent: "flex-end", margin: "16px 0 16px 0", gap: "16px" }}>
                        {/*<Button onClick={() => setOpenPreview(true)}>Vista previa</Button>*/}
                        <LoadingButton
                            loading={loading}
                            type="submit"
                            variant="contained"
                            sx={{ color: "#fff", borderRadius: '1em' }}

                        >
                            Actualizar
                        </LoadingButton>

                    </Grid>
                </Grid>
            </form>
        );
    } else if (bgStyle === 2) {
        return (
            <Grid container mt="16px">
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "row" }}>
                    <Grid item xs>
                        <TextField
                            label=""
                            size="small"
                            name="bg_color1"
                            value={color1}
                            onChange={(e) => setColor1(e.target.value)}
                            sx={{
                                width: "100%",
                                '& input[type="color"]': {
                                    height: "45px",   // Altura más grande
                                    width: "60px",    // Ancho al 100% del input
                                    padding: 0,
                                    paddingLeft: '2px',       // Elimina el padding del input de color
                                    borderRadius: '100%' // Agrega bordes redondeados
                                }
                            }}
                            type="color"
                            InputProps={{
                                endAdornment: <Typography sx={{ marginLeft: "20px" }}>{color1}</Typography>,
                                style: { display: "flex", alignItems: "center", padding: 0 } // Alinea el texto verticalmente con el cuadro de color
                            }}
                        />
                    </Grid>

                </Grid>
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", paddingTop: '15px' }}>
                    <Grid item xs>
                        <TextField
                            label=""
                            size="small"
                            name="bg_color2"
                            value={color2}
                            onChange={(e) => setColor2(e.target.value)}
                            sx={{
                                width: "100%",
                                '& input[type="color"]': {
                                    height: "45px",   // Altura más grande
                                    width: "60px",    // Ancho al 100% del input
                                    padding: 0,
                                    paddingLeft: '2px',       // Elimina el padding del input de color
                                    borderRadius: '100%' // Agrega bordes redondeados
                                }
                            }}
                            type="color"
                            InputProps={{
                                endAdornment: <Typography sx={{ marginLeft: "20px" }}>{color2}</Typography>,
                                style: { display: "flex", alignItems: "center", padding: 0 } // Alinea el texto verticalmente con el cuadro de color
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} mt="8px">
                    <FormControl
                        sx={{ width: "100%", marginTop: '15px' }}
                        variant="outlined"
                        size="small"
                    >
                        <InputLabel id="demo-simple-select-filled-label">
                            Orientación
                        </InputLabel>
                        <Select

                            name={"seccion"}
                            labelId="demo-simple-select-standard-label"
                            label={"Orientación"}
                            value={gradiant}
                            onChange={(e) => setGradiant(e.target.value)}
                        >

                            <MenuItem key={1} value={"top"}>
                                Arriba
                            </MenuItem>
                            <MenuItem key={2} value={"bottom"}>
                                Abajo
                            </MenuItem>
                            <MenuItem key={3} value={"left"}>
                                Izquierda
                            </MenuItem>
                            <MenuItem key={4} value={"right"}>
                                Derecha
                            </MenuItem>
                        </Select>
                    </FormControl>

                </Grid>


                <Grid item xs={12} sx={{ marginTop: "16px" }}>
                    <ToggleButtons handleAlignment={handleAlignment} alignment={alignment} handleTitle={handleTitle} title={title} data={data} />
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", width: "100%", justifyContent: "flex-end", margin: "16px 0 16px 0", gap: "16px" }}>
                    {/*<Button onClick={() => setOpenPreview(true)}>Vista previa</Button>*/}
                    <LoadingButton
                        loading={loading}

                        onClick={() => { sendGradiant() }}

                        variant="contained"
                        sx={{ color: "#fff", borderRadius: '1em' }}
                    >
                        Actualizar
                    </LoadingButton>

                </Grid>
            </Grid>
        );
    } else if (bgStyle === 1) {
        return (
            <Grid container mt="16px">
                <Grid container alignItems="center">
                    <Grid item xs>
                        <TextField
                            label=""
                            size="small"
                            name="bg_color1"
                            value={color1}
                            onChange={(e) => setColor1(e.target.value)}
                            sx={{
                                width: "100%",
                                '& input[type="color"]': {
                                    height: "45px",   // Altura más grande
                                    width: "60px",    // Ancho al 100% del input
                                    padding: 0,
                                    paddingLeft: '2px',       // Elimina el padding del input de color
                                    borderRadius: '100%' // Agrega bordes redondeados
                                }
                            }}
                            type="color"
                            InputProps={{
                                endAdornment: <Typography sx={{ marginLeft: "20px" }}>{color1}</Typography>,
                                style: { display: "flex", alignItems: "center", padding: 0 } // Alinea el texto verticalmente con el cuadro de color
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: "16px" }}>
                    <ToggleButtons handleAlignment={handleAlignment} alignment={alignment} handleTitle={handleTitle} title={title} data={data} />
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", width: "100%", justifyContent: "flex-end", margin: "32px 0 16px 0", gap: "16px" }}>
                    {/*<Button onClick={() => setOpenPreview(true)}>Vista previa</Button>*/}
                    <LoadingButton
                        loading={loading}
                        onClick={() => { sendColor() }}
                        variant="contained"
                        sx={{ color: "#fff", borderRadius: '1em' }}
                    >
                        Actualizar
                    </LoadingButton>

                </Grid>
            </Grid>
        )
    } else {
        return (<></>)
    }
};

function valueLabelFormat(value) {


    return `${value} %`;
}

export default BgStyle;