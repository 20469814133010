import React, { useState, useEffect } from "react";
import BasicModal from "../../../../components/GenericModal";
import { AvailablePresentation } from "../../../../api/availablePresentation";
import {
  ProductionOrder,
  ProductionReceive,
} from "../../../../api/productionOrder";
import BasicTable from "../../../../components/GenericTable";
import { Production } from "../../../../api/productionOrder";
import { Grid, Divider, TextField } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { useAuth } from "user-context-fran-dev";
import { useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initProductionReceived,
  ValProductionReceived,
} from "../../../../utils/formHelper";
import ImageSize from "../../../../components/ImageSize";
import { GenericEmptyTable } from "../../../../components/GenericEmptyTable";
import CircleIcon from "@mui/icons-material/Circle";
import {
  initValWareHouseProduction,
  valSchWareHouseProduction,
} from "../../../../utils/formHelper";
import CustomizedDialogs from "../../../../components/GenericsModals";

export const ProductionDelivered = ({
  open,
  setOpen,
  data,
  updateOrder,
  order,
}) => {
  const [table, setTable] = useState("");
  const [update, setUpdate] = useState(false);
  const { id } = useParams();
  const { logout } = useAuth();
  const [newData, setNewData] = useState("");

  useEffect(() => {
    updateOrder();
    UpdateProductionM();
    getPresentation();
  }, [update]);

  const getPresentation = () => {
    (async () => {
      let irequestFilter = [];
      irequestFilter.push({
        key: "wh",
        value: order.warehouse.id,
      });
      irequestFilter.push({ key: "pres", value: `${data.idPresentation}` });
      let getPresentation = new AvailablePresentation();
      let response = await getPresentation.filter(irequestFilter, logout);
      console.log(response.data);
      setTable(response.data);
    })();
  };

  const UpdateProductionM = () => {
    (async () => {
      let getProduction = new ProductionOrder();
      let response = await getProduction.getAllByIdAuth(id, logout);
      console.log(response.data);
      let recipe = response.data.recipes
        .find((n) => n.id === data.idRecipe)
        .productions.find((m) => m.id === data.idProduction);
      setNewData(recipe);
    })();
  };

  return (
    <>
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title="Entregar artículo"
        size={"md"}
      >
        {newData && (
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={2} className="">
                  <ImageSize
                    img={newData.model?.image ? newData.model.image.url : ""}
                    weight={2}
                  />
                  {/*              <img
                  src={
                    newData.model?.image ? newData.model.image.url : ""
                  }
                  style={{ width: "90%", height: "auto" }}
                ></img> */}
                </Grid>
                <Grid item xs={10}>
                  <Grid item xs={12}>
                    <h3>{newData.model.product.name}</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <h4>{newData.model.product.sku || ""}</h4>
                  </Grid>
                  <Grid item xs={12}>
                    <h4>{newData.model.name}</h4>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider
                sx={{
                  height: "1px",
                  borderColor: "text.primary",
                  backgroundColor: "text.primary",
                  marginTop: "8px",
                }}
              ></Divider>
            </Grid>
            <Grid container mt={1}>
              <Grid item xs={5}>
                <h4>{`Reservados: ${newData.quantity}`}</h4>
              </Grid>
              <Grid item xs={5} className="">
                <h4>{`Pendientes: ${newData.quantity_pending}`}</h4>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <br />
                {table && newData.quantity_pending > 0 && (
                  <BasicTable
                    className="marginTable"
                    titles={[
                      "Presentación",
                      "Estatus",
                      "Disponible",
                      "BarCode",
                      "Cantidad",
                      "Acción",
                    ]}
                  >
                    {table.map((row, index) => (
                      <RowInfo
                        row={row}
                        key={index}
                        data={newData}
                        order={order}
                        setUpdate={setUpdate}
                      />
                    ))}
                  </BasicTable>
                )}
                {!table ||
                  (newData.quantity_pending === 0 && (
                    <GenericEmptyTable msg={"Entregado"} />
                  ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </CustomizedDialogs>
    </>
  );
};

const RowInfo = ({ row, data, setUpdate }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [cantidad, setCantidad] = useState(0);
  const [error, setError] = useState("");

  const handleCantidad = (e) => {
    setCantidad(e.target.value);
  };

  const formik = useFormik({
    initialValues: initValWareHouseProduction(data, row),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchWareHouseProduction(data)),
    onSubmit: async (formData) => {
      setDelivered(formData);
    },
  });

  const setDelivered = async (formData) => {
    /* console.log("entre"); */
    setLoading(true);
    /*     const formData = {
      quantity: cantidad,
      production: data.id,
      available: row.id, 
    };
    console.log(formData); */

    let setOrder = new Production();
    let response = await setOrder.postDataAuth(formData, logout);
    console.log(response.data);

    setUpdate((prev) => !prev);
    setLoading(false);
    /*     setOpen(false) */
  };

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover>
      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <Typography variant="subtitle2" component="div">
          {row.presentation.name}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <Typography variant="subtitle2" component="div">
          {row.presentation.is_active ? (
            <CircleIcon sx={{ color: "green", fontSize: 15 }} />
          ) : (
            <CircleIcon sx={{ color: "red", fontSize: 15 }} />
          )}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <Typography variant="subtitle2" component="div">
          {row.available}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <Typography variant="subtitle2" component="div">
          {row.barcode}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            /*     inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}  */
            type="number"
            fullWidth
            label="Cantidad"
            size="small"
            name="quantity"
            value={formik.values.quantity}
            onChange={formik.handleChange}
            error={formik.touched.quantity && Boolean(formik.errors.quantity)}
            helperText={formik.touched.quantity ? formik.errors.quantity : ""}
          />
        </form>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <form onSubmit={formik.handleSubmit}>
          <LoadingButton
            loading={loading}
            fullWidth
            variant="text"
            type="submit"
          >
            Entregar
          </LoadingButton>
        </form>
      </TableCell>
    </TableRow>
  );
};

export const ProductionReceived = ({ open, setOpen, data, update, orden }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [doneS, setDoneS] = useState("");

  /* console.log(data); */

  const formik = useFormik({
    initialValues: initProductionReceived(data, orden),
    enableReinitialize: true,
    validationSchema: Yup.object(ValProductionReceived(data)),
    onSubmit: async (formData) => {
      setLoading(true);

      formik.values.done = Number(doneS);

      console.log(formData);
      /* console.log(formik.values); */
      let setOrder = new ProductionReceive();
      let response = await setOrder.postDataAuth(formData, logout);
      update();
      setLoading(false);
      setOpen(false);
    },
  });

  return (
    <BasicModal open={open} setOpen={setOpen} title="Recibir articulo">
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              {/*    <img
                src={data.model?.image? data.model.image.url :""}
                style={{ width: "90%", height: "auto" }}
              ></img> */}
              <ImageSize
                img={data.model?.image ? data.model.image.url : ""}
                weight={2}
              />
            </Grid>
            <Grid item xs={10}>
              <Grid item xs={12} className="">
                <h3>{data.model.product.name}</h3>
              </Grid>
              <Grid item xs={12} className="">
                <h4>{data.model.product.sku}</h4>
              </Grid>
              <Grid item xs={12} className="">
                <h4>{data.model.name}</h4>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider
            sx={{
              height: "1px",
              borderColor: "text.primary",
              backgroundColor: "text.primary",
              marginTop: "8px",
            }}
          ></Divider>
        </Grid>
        <Grid container mt={1} mb={1}>
          <Grid item xs={6} className="">
            <h4>{`Ordenados: ${data.quantity}`}</h4>
          </Grid>
          <Grid item xs={5} className="">
            <h4>{`Pendientes: ${data.quantity_pending}`}</h4>
          </Grid>
        </Grid>
        {data.status !== 4 ? (
          <Grid item xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={1} alignItems="center" mt={2}>
                <Grid item xs={2.5}>
                  <TextField
                    fullWidth
                    label="Presentación"
                    defaultValue={data.presentation.name}
                    InputProps={{
                      readOnly: true,
                    }}
                    focused={false}
                    size="small"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={2.6}>
                  <TextField
                    fullWidth
                    name="barcode"
                    value={formik.values.barcode}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.barcode && Boolean(formik.errors.barcode)
                    }
                    helperText={
                      formik.touched.barcode ? formik.errors.barcode : ""
                    }
                    autoComplete="none"
                    label="Barcode"
                    margin="dense"
                    size="small"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={2.6}>
                  <TextField
                    fullWidth
                    name="quantity"
                    value={formik.values.quantity}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.quantity && Boolean(formik.errors.quantity)
                    }
                    helperText={
                      formik.touched.quantity ? formik.errors.quantity : ""
                    }
                    autoComplete="none"
                    label="Cantidad"
                    margin="dense"
                    size="small"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={2}>
                  <LoadingButton
                    loading={loading}
                    fullWidth
                    type="submit"
                    variant="text"
                    sx={{ color: "primary" }}
                    onClick={() => setDoneS("2")}
                  >
                    Recibir y terminar
                  </LoadingButton>
                </Grid>
                <Grid item xs={2}>
                  <LoadingButton
                    loading={loading}
                    fullWidth
                    type="submit"
                    variant="text"
                    sx={{ color: "primary" }}
                    onClick={() => setDoneS("1")}
                  >
                    Recibir
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Grid>
        ) : (
          <Grid container justifyContent="center">
            {" "}
            <Typography variant="h5">Recibido</Typography>
          </Grid>
        )}
      </Grid>
    </BasicModal>
  );
};
