import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import BasicTable from "../../components/GenericTable";
import { useNavigate } from "react-router-dom";
import { PaginationGeneric } from "react-generic-pagination";
import Container from "../../layout/gridContainer";
import { RecipeGroup } from "../../api/recipeManages";
import RecipesTable from "./recipes/recipesTable";
import AddRecipe from "./recipes/addRecipe";
import { useAuth } from "user-context-fran-dev";
import TextField from "@mui/material/TextField";
import { GenericEmptyTable } from "../../components/GenericEmptyTable";
import CircularProgress from "@mui/material/CircularProgress";

const InventoryRecipes = () => {
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(true);

  const totalPerPage = 10;
  const [page, setPage] = useState(1); //primer request en page 1
  const [totalpages, setTotalPages] = useState("");
  const [table, setTable] = useState(null);
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    navigate(`page=${page}`);
    getRecipes();
  }, [page]);

  const GoToDetailRecipe = (id) => {
    window.localStorage.setItem("menu", 14);
    navigate(`detail/${id}`);
  };

  const pageSetter = (n) => {
    setPage(n);
    /* navigate(`page=${page}`) */
  };

  const getRecipes = () => {
    (async () => {
      setLoading(true);
      let irequestFilter = [];
      irequestFilter.push({ key: "page", value: `${page}` });
      irequestFilter.push({ key: "items", value: `${totalPerPage}` });
      let getRecipe = new RecipeGroup();
      let response = await getRecipe.filter(irequestFilter, logout);
      setTotalPages(response.data.pagination);
      setTable(response.data.data);
      setLoading(false);
      console.log(response.data);
    })();
  };

  const SetRecipeGroup = async (id) => {
    console.log(id.id);
    let formData = {
      product: id.id.toString(),
    };
    let setRecipe = new RecipeGroup();
    let response = await setRecipe.postDataAuth(formData, logout);

    GoToDetailRecipe(response.data.id);
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={5}>
              <TextField
                fullWidth
                label="Buscar orden por número de orden"
                variant="standard"
              />
            </Grid>
            <Grid item xs={0} sm={2} />

            <Grid item xs={12} sm={5}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  console.log(!open);
                  setOpen(true);
                }}
              >
                {" "}
                Crear Variante{" "}
              </Button>
              <AddRecipe
                open={open}
                setOpen={setOpen}
                doSomething={SetRecipeGroup}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {table && (
            <>
              <BasicTable
                className="marginTable"
                titles={[
                  "Imagen",
                  "Nombre / SKU",
                  "Categoría",
                  "Estatus",
                  "Más",
                ]}
              >
                {table.map((data, index) => (
                  <RecipesTable
                    key={index}
                    data={data}
                    GoToDetailRecipe={GoToDetailRecipe}
                  />
                ))}
              </BasicTable>
              <Grid item xs={12} mt={2}>
                <PaginationGeneric
                  className="paginationStyle"
                  page={page}
                  total={totalpages}
                  pageSetter={pageSetter}
                ></PaginationGeneric>
              </Grid>
            </>
          )}
          {!table && loading && (
            <Grid container justifyContent="center" p={"24px"}>
              <CircularProgress />
            </Grid>
          )}
          {!table && !loading && <GenericEmptyTable />}
        </Grid>
      </Grid>
    </Container>
  );
};

export default InventoryRecipes;
