import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GenericAddress from "../../../../components/GenericAddress";
import Container from "../../../../layout/gridContainer";
import GenericProfile from "../../../../components/GenericProfile";
import CustomerService from "../../../../api/customerService";
import PaymentConditions from "../../../../components/paymentConditions";
import Phonebook from "../../../../components/inventario/phonebook";
import Comments from "../../../../components/inventario/comments";
import { useAuth } from "user-context-fran-dev";

const CustomerDetails = (props) => {
  const { logout } = useAuth();
  const { id } = useParams();
  const [dataCustomer, setDataCustomer] = useState();

  useEffect(() => {
    GetInfoCustomer();
    return () => {
      window.localStorage.setItem("menu", 5);
    };
  }, []);

  const GetInfoCustomer = () => {
    (async () => {
      let getCustomer = new CustomerService();
      let response = await getCustomer.getAllByIdAuth(id, logout);
      console.log("response", response);
      setDataCustomer(response.data);
    })();
  };

  return (
    <>
      {dataCustomer && (
        <Container>
          <GenericProfile
            data={dataCustomer}
            UpdateInfo={GetInfoCustomer}
            target={"client"}
          />

          <GenericAddress
            data={dataCustomer}
            UpdateInfo={GetInfoCustomer}
            target={"client"}
          />
          <PaymentConditions
            data={dataCustomer}
            UpdateInfo={GetInfoCustomer}
            target={"client"}
          />
          <Phonebook
            data={dataCustomer}
            UpdateInfo={GetInfoCustomer}
            target={"client"}
          />
          <Comments
            data={dataCustomer}
            UpdateInfo={GetInfoCustomer}
            target={"client"}
          />
        </Container>
      )}
    </>
  );
};
export default CustomerDetails;
