import React, { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import BasicTable from "../../components/GenericTable";
import Container from "../../layout/gridContainer";
import SalesTable from "./sales/salesTable";
import AddSale from "./sales/addSale";
import SalesService from "../../api/salesService";
import { PaginationGeneric } from "react-generic-pagination";
import { useNavigate } from "react-router-dom";
import {
  GenericEmptyTable,
  GenericSkeletonTable,
} from "../../components/GenericEmptyTable";
import CircularProgress from "@mui/material/CircularProgress";
import GenericSearch from "../../components/GenericSearch/GenericSearch";
import useNexOrder from "../../hooks/nextOrder";
import { useAuth } from "user-context-fran-dev";

const InventorySales = () => {
  const [openModal, setOpenModal] = useState();
  const [table, setTable] = useState(null);
  const [page, setPage] = useState(1); //primer request en page 1
  const [totalpages, setTotalPages] = useState("");
  const [loading, setLoading] = useState(true);
  const totalPerPage = 10;
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [idOrder] = useNexOrder(table);

  useEffect(() => {
    navigate(`page=${page}`);
    UpdateSalesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const UpdateSalesList = () => {
    (async () => {
      setLoading(true);
      let irequestFilter = [];
      irequestFilter.push({ key: "page", value: `${page}` });
      irequestFilter.push({ key: "items", value: `${totalPerPage}` });
      let getSales = new SalesService();
      let response = await getSales.filter(irequestFilter, logout);
      setTotalPages(response.data.pagination);
      setTable(response.data.data);
      setLoading(false);
    })();
  };

  const pageSetter = (page) => {
    setPage(page);
    /* navigate(`page=${page}`) */
  };

  return (
    <Container>
      <Grid container spacing={2}>
        {/*       <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="div">
            <strong>Proveedores </strong>
          </Typography>
        </Grid> */}
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            className="title-card"
            sx={{ paddingBottom: "2%" }}
          >
            <strong>Ventas</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <GenericSearch
                Service={SalesService}
                setData={setTable}
                label={"Buscar por numero de orden"}
              ></GenericSearch>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={() => setOpenModal(true)}
                    fullWidth
                    variant="contained"
                  >
                    {" "}
                    Agregar Venta
                  </Button>
                  {idOrder && (
                    <AddSale
                      openModal={openModal}
                      UpdateSalesList={UpdateSalesList}
                      setOpenModal={setOpenModal}
                      idOrder={idOrder}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <>
            <BasicTable
              className="marginTable"
              titles={[
                "Num. orden / Fecha",
                "Cliente / Almacén ",
                "Estatus",
                "Total",
                "Más",
              ]}
            >
              {loading && <GenericSkeletonTable />}
              {!loading && !table && <GenericEmptyTable />}
              {!loading &&
                table &&
                table.map((data, index) => (
                  <SalesTable key={index} data={data}></SalesTable>
                ))}
            </BasicTable>
            <div className="margin">
              <PaginationGeneric
                className="paginationStyle"
                page={1}
                total={totalpages}
                pageSetter={pageSetter}
              ></PaginationGeneric>
            </div>
          </>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InventorySales;
