import React, { useState, useEffect } from "react";
import { Suppliers } from "../../api/supplierService";
import CustomerService from "../../api/customerService";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import EmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import PlaceIcon from "@mui/icons-material/PlaceOutlined";
import CopyIcon from "@mui/icons-material/FileCopyOutlined";
import { GenericDrawer } from "../keskiCreator/genericDrawer";
import { CopyToClipboard } from "react-copy-to-clipboard";

export function DrawerSupplier(props) {
  const [data, setData] = useState("");
  const iconList = [PhoneIcon, EmailIcon, PlaceIcon];
  const prop_path = ["phone", "email", "address"];
  const credit_prop_path = ["credit_days", "credit_limit", "discount"];
  const credit_label = ["Días de crédito:", "Descuento:", "Límite de crédito:"];

  useEffect(() => {
    if (props.id) {
      fetchData();
      console.log(props.type);
    }
  }, []);

  const fetchData = () => {
    (async () => {
      const services = {
        purchase: new Suppliers(),
        sales: new CustomerService(),
      };
      const service = services[props.type];
      let response = await service.getAllByIdAuth(props.id, props.logout);
      //  console.log("supplier_data:", response);
      setData(response?.data);
    })();
  };

  const paths = prop_path.map((val, index) => ({
    path: val,
    icon: iconList[index], // Set value to the corresponding key
  }));

  const credit_paths = credit_label.map((val, index) => ({
    label: val,
    path: credit_prop_path[index], // Set value to the corresponding key
  }));

  return (
    <GenericDrawer
      open={props.open}
      toggleDrawer={props.toggleDrawer}
      anchor={"right2"}
      drawerWidth="30%"
    >
      <Box p={"16px"}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "16px",
          }}
        >
          <Typography fontSize={"20px"} p={"8px 0"}>
            <b>Vista previa</b>
          </Typography>

          <IconButton onClick={props.close}>
            <CloseSharpIcon fontSize="small" />
          </IconButton>
        </Grid>
        {data && (
          <>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">{data.business_name}</Typography>
              <Button
                variant="outlined"
                sx={{
                  pointerEvents: "none",
                  borderRadius: "60px",
                  color: "GrayText",
                  height: "32px",
                  textTransform: "none",
                  width: "110px",
                }}
              >
                Empresa
              </Button>
            </Stack>

            <Typography sx={{ textTransform: "uppercase", padding: "8px 0" }}>
              {data.rfc}
            </Typography>

            <Divider sx={{ m: "16px 0" }} />

            {paths.map(({ path, icon: Icon }, index) => (
              <Grid
                display="flex"
                key={index}
                padding="8px 0"
                justifyContent="space-between"
              >
                <Stack direction="row">
                  <Icon style={{ marginRight: "10px" }} />
                  <Typography>{data[path] ? data[path] : <>--</>}</Typography>
                </Stack>
                <CopyToClipboard
                  text={path === "email" ? data.email : data.phone}
                >
                  <CopyIcon
                    fontSize="small"
                    sx={{
                      display: path === "address" ? "none" : "",
                      color: "GrayText",
                      cursor: "pointer",
                    }}
                  />
                </CopyToClipboard>
              </Grid>
            ))}

            <Divider sx={{ m: "16px 0" }} />

            {credit_paths.map(({ label, path }) => (
              <Grid display="flex" p="8px 0" key={path}>
                <Typography mr={1}>{label}</Typography>
                <Typography>{data[path] ? data[path] : 0}</Typography>
              </Grid>
            ))}

            <Divider sx={{ m: "16px 0" }} />

            <Typography p="8px 0">
              <strong>CONTACTOS</strong>
            </Typography>
            <Box display="flex" p="16px 0">
              <Avatar sx={{ mr: 1 }}>
                {data?.user.first_name ? data?.user.first_name[0] : "-"}
              </Avatar>
              <Grid
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
              >
                <Stack direction="column">
                  <Typography>
                    {data?.user.first_name ? data?.user.first_name : "-"}
                  </Typography>
                  <Typography>
                    {data?.user.email ? data?.user.email : "-"}
                  </Typography>
                </Stack>
                <Typography align="center">
                  {data.contacts && "tel:" + data.contacts}
                </Typography>
              </Grid>
            </Box>

            <Divider sx={{ m: "16px 0" }} />

            <Typography p="8px 0">
              <strong>COMENTARIOS</strong>
            </Typography>
            <Typography p="8px 0">
              {data.comments ? data.comments : "No hay comentarios aún"}
            </Typography>
          </>
        )}
      </Box>
    </GenericDrawer>
  );
}
