import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import Collapse from "@mui/material/Collapse";
import { Box } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useFormik } from "formik";
import { Grid, TextField, Button } from "@mui/material";
import { useAuth } from "user-context-fran-dev";
import { ProductService, ProductModelService } from "../../api/productService";
import CircularProgress from "@mui/material/CircularProgress";
import { GenericEmptyTable } from "../GenericEmptyTable";
import { GenericHeaderExpandable } from "../GenericHeader";
import ImageSize from "../ImageSize";
import { InventoryServicePresentations } from "../../api/inventoryService";
import { ArticleTableSearcher } from "./ArticleSearcher";
import { useNavigate } from "react-router-dom";

export function Row(props) {
  const { row, toggleDrawer, setDataProduct, wh, display } = props;
  const prop_path = ["name", "sku", "is_active", "category.name"];
  const [open, setOpen] = useState(false);
  const [dataPresentation, setDataResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  /* console.log('dataPresentation', dataPresentation) */

  async function getinfo(id) {
    setLoading(true);
    let irequestFilter = [];
    irequestFilter.push({ key: "product", value: `${id}` }); //Modifique el endpoint que se utiliza
    irequestFilter.push({ key: "is_active", value: `true` });

    let products = new ProductModelService();
    let dataRes = await products.filter(irequestFilter, logout);
    console.log("DATA:", dataRes);
    setDataResponse(dataRes?.data);
    /* setDataProduct(dataRes) */
    /* setDataProduct(row) */
    setLoading(false);
  }

  async function getAvailableDataModal(idmodel, product, model) {
    let irequestFilter = [];

    irequestFilter.push({ key: "product", value: `${product}` });
    irequestFilter.push({ key: "mdl", value: `${idmodel}` });
    irequestFilter.push({ key: "wh", value: `${wh?.warehouse.id}` });

    let serviceInstance = new InventoryServicePresentations();
    let response2 = await serviceInstance.filter(irequestFilter, logout);

    setDataProduct({
      product: row,
      presentations: response2?.data,
      model: model,
    });
    console.log({ product: row, presentations: response2?.data, model: model });
  }

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, key) => acc && acc[key], obj);
  };
  return (
    <>
      <TableRow
        onDoubleClick={() => {
          if (!wh) {
            window.localStorage.setItem("menu", 10);
            navigate(`detail/${row.id}`);
          }
        }}
        sx={{
          display: display === false ? " " : "none",
          cursor: "pointer",
        }}
        hover
      >
        <TableCell
          align="left"
          sx={{ minWidth: "200px", display: wh ? "none" : "" }}
        >
          <Grid ml={"8px"}>
            <ImageSize
              img={
                row?.main_model?.image?.url ? row.main_model?.image?.url : ""
              }
              weight={1}
            />
          </Grid>
        </TableCell>

        {prop_path.map((val, index) => (
          <TableCell
            align="left"
            key={index}
            sx={{
              minWidth: !wh && val === "category.name" ? "50px" : "220px",
              p: !wh && val === "category.name" ? "6px 0 0 25px" : "6px 16px",
            }}
          >
            {val != "is_active" ? (
              <Typography>{getNestedValue(row, val)}</Typography>
            ) : (
              <Typography>
                <strong style={{ color: row.is_active ? "green" : "red" }}>
                  {row.is_active ? "Activo" : "Inactivo"}
                </strong>
              </Typography>
            )}
          </TableCell>
        ))}
        <TableCell sx={{ display: wh ? "" : "none" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open);
              if (!open) {
                getinfo(row.id);
              }
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: "0 32px" }} colSpan={6}>
          <Collapse in={open} timeout="auto">
            {dataPresentation ? (
              //console.log('datapresentation', dataPresentation)
              <Box sx={{ margin: 1 }}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 2 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  p={0}
                >
                  {dataPresentation.map((model, index) => (
                    <Grid item xs={2} sm={4} md={4} key={index}>
                      <Button
                        onClick={() => {
                          toggleDrawer();
                          getAvailableDataModal(model.id, row.id, model);
                        }}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          p: "7px 0",
                          width: "80%",
                        }}
                        variant="outlined"
                      >
                        <ImageSize img={model?.image?.url} weight={2} />
                        <Typography
                          width={"100%"}
                          fontSize={"18px"}
                          align="center"
                          m={"0 16px"}
                        >
                          {model.name}
                        </Typography>
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              <div className="model-null">
                {loading && <CircularProgress />}
                {!dataPresentation && !loading && (
                  <GenericEmptyTable msg={"No hay modelos de este producto"} />
                )}
              </div>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export function ArticleExpandableView(props) {
  const { type, reloadDataOforder, wh } = props;
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div>
      <GenericHeaderExpandable
        title="Agregar productos"
        arrow={expanded}
        setArrow={setExpanded}
      >
        <ArticleTableSearcher
          type={type}
          reloadDataOforder={reloadDataOforder}
          wh={wh}
        />
      </GenericHeaderExpandable>
    </div>
  );
}
