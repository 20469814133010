import React, { useState } from "react";
import { Typography } from "@mui/material";
import GenericTable from "../../GenericTable";
import { CardHeader } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { GenericEmptyTable } from "../../GenericEmptyTable";

const ArticlesListScrap = (props) => {
  const { data /* setOpenModal, setModalInfo */ } = props;
  /*   const [open, setOpen] = useState(false); */
  console.log("scrap", data);

  return (
    <>
      {data.scrap ? (
        <div className="articles-table">
          <GenericTable
            titles={[
              "Nombre / #parte ",
              "Modelo / Presentación",
              "Barcode",
              "Cantidad",
            ]}
          >
            {data.scrap.map((row, index) => (
              /* row.quantity_pending != 0 && */

              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover
              >
                <TableCell align="left" sx={{ maxWidth: "100" }}>
                  <CardHeader
                    sx={{ m: 0, p: 0 }}
                    title={
                      <Typography variant="subtitle2" component="div">
                        {row.model.product.name}
                      </Typography>
                    }
                    subheader={row.model.id}
                  />
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: "100px" }}>
                  <CardHeader
                    sx={{ m: 0, p: 0 }}
                    title={
                      <Typography variant="subtitle2" component="div">
                        {row.model.name}
                      </Typography>
                    }
                    subheader={row.presentation.name}
                  />
                </TableCell>

                <TableCell align="left" sx={{ maxWidth: "100px" }}>
                  <Typography variant="subtitle1" component="div">
                    {row.barcode}
                  </Typography>
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: "100px" }}>
                  <CardHeader
                    title={
                      <Typography variant="subtitle2" component="div">
                        {row.quantity}
                      </Typography>
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </GenericTable>
        </div>
      ) : (
        <GenericEmptyTable msg={"No hay artículos agregados"} />
      )}
    </>
  );
};

export default ArticlesListScrap;
