import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import EditorConvertToHTML from '../../components/RichText';
import Container from '../../layout/gridContainer';
import '../../scss/adminBusiness.scss'
import { useAuth } from "user-context-fran-dev";
import BusinessManages from '../../api/businessManages';
import { UseerrorOrsuccess, useNotification } from '../../hooks/snackBarResponse'
import CreatorAppBar from '../../components/keskiCreator/creatorAppBar';
import { useLocation } from 'react-router-dom';

const Politics = () => {
  const [terms, setTerms] = useState('');
  const [privacy, setPrivacy] = useState('');
  const [id, setId] = useState("");
  const { logout } = useAuth();
  let location = useLocation();

  const [msg, sendNotification] = useNotification();

  const UpdatePolitics = async () => {

    (async () => {
      let useBusines = new BusinessManages();
      let response = await useBusines.getAllAuth(logout);
      console.log('response', response)
      setTerms(response.data?.terms_conditions || '');
      setPrivacy(response.data?.notice_privacy || '');
      setId(response.data?.id || '');
    })();

  }
  const setPolitics = async (id, formData) => {

    let updateAddress = new BusinessManages();
    const response = await updateAddress.editDataAuth(id, formData, logout);

    sendNotification(UseerrorOrsuccess(response))

  }


  useEffect(() => {
    UpdatePolitics();
  }, []);

  const handleOnClickTerms = (text) => {
    setTerms(text)
    /* console.log(terms) */
  }
  const handleOnClickPrivacy = (text) => {
    setPrivacy(text)
    /* console.log(privacy) */
  }

  const endpointMap = {
    '/landing/root': 'landing/content/1',
    '/landing/store': 'landing/content/2',
    '/landing/about': 'landing/content/3',
    '/landing/catalog': 'landing/content/4',
    '/landing/services': 'landing/content/5',
    '/landing/blog': 'landing/content/6',
    '/landing/location': 'landing/content/7',
  };


  function getEndpoint() { // La sección a la que queremos irnos
    const endpoint = endpointMap[location.pathname] || 'default/endpoint';
    //console.log("Estás en el endpoint:", endpoint);
    return endpoint;
  }

  return (
    <>
      <CreatorAppBar getEndpoint={getEndpoint} />
      <Box
        sx={{ paddingTop: '60px' }}
      >
        <div>
          {id &&
            <Container>
              <Grid item xs={12} sm={8} md={8} lg={8}>
                <Typography
                  variant="h5"
                  gutterBottom
                  component="div"
                  className="title-card"
                  sx={{ paddingBottom: '2%' }}
                >
                  <strong>Legal</strong>
                </Typography>
              </Grid>

              <Divider sx={{
                m: "5px 0",
                borderWidth: 1,
              }} />

              <Box sx={{ mt: '2em' }}>
                <Typography variant="h6" gutterBottom component="div">
                  <strong>Términos y condiciones</strong>
                </Typography>
                <Typography gutterBottom component="div">
                  Escribe aquí tus terminos y condiciones
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <br />
                    <br />
                    <Paper sx={{ backgroundColor: '#fff' }}>
                      <EditorConvertToHTML handleClick={handleOnClickTerms} data={terms} />
                    </Paper>
                    <br />
                    <br />
                  </Grid>

                  <Grid item xs={0} sm={7} md={9} lg={10} />
                  <Grid item xs={12} sm={5} md={3} lg={2} >
                    <Button
                      fullWidth

                      type="submit"
                      onClick={() => setPolitics(id, { terms_conditions: terms })}
                      variant="contained"
                    >
                      Actualizar
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              <Divider sx={{
                m: "40px 0",
                borderWidth: 1,
              }} />
              <Box sx={{ mt: '2em' }}>
                <Typography variant="h6" gutterBottom component="div">
                  <strong>Aviso de privacidad</strong>
                </Typography>
                <Typography gutterBottom component="div">
                  Escribe aquí tu aviso de privacidad
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <br />
                    <br />
                    <Paper sx={{ backgroundColor: '#fff' }}>
                      <EditorConvertToHTML handleClick={handleOnClickPrivacy} data={privacy} />
                    </Paper>
                    <br />
                    <br />
                  </Grid>
                  <Grid item xs={0} sm={7} md={9} lg={10} />
                  <Grid item xs={12} sm={5} md={3} lg={2} >
                    <Button
                      fullWidth
                      /* sx={{padding: "0px 8px"}} */
                      type="submit"
                      onClick={() => setPolitics(id, { notice_privacy: privacy })}
                      variant="contained"

                    >
                      Actualizar
                    </Button>
                  </Grid>
                </Grid>
              </Box>

            </Container>
          }
        </div>
      </Box>
    </>
  );
}

export default Politics;