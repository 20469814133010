import React, { useState } from "react";
import Container from "../../layout/gridContainer";
import { Box, Button, CircularProgress, Divider, Grid, Stack, Typography } from "@mui/material";
import { BottonModalDiag, ModalRight } from "../GenericsModals";
import { useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import { Sections } from 'keski_lib_catalog'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { SectionsCreator, SectionsCreatorWithIA } from "../../api/landing/creatorManages";
import { useAuth } from "user-context-fran-dev";
import { useNavigate } from "react-router-dom";
import { UseerrorOrsuccess, useNotification } from "../../hooks/snackBarResponse"
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { BusinessSettings } from "../../api/businessManages";
import { BASE_PATH } from "../../utils/constants";
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import LoadingButton from '@mui/lab/LoadingButton';

import { TabStyles } from "../TabMenu/TabStyles";
import DetailCreator, { details } from '../keskiCreator/details'
import DetailCreatorElement from "./detailsContent";
import { ConfirmAction } from "../ConfirmAction/ConfirmAction";
import CreatorAppBar from "./creatorAppBar";
import { BaseService } from 'base-service-fran-dev'
import { useLocation } from 'react-router-dom';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import GenericSection from "../ComponentGeneric/GenericSection";


const Creator = ({ view }) => {
  const [open, setOpen] = useState(false);
  const [list, setList] = useState("");
  const [sections, setSections] = useState()
  const [msg, sendNotification] = useNotification();
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState("");
  const navigate = useNavigate();
  const { logout } = useAuth();
  let location = useLocation();

  const [viewId, setViewId] = useState("")
  const [selectedSection, setSelectedSection] = useState(""); // Estado para almacenar el ID de la sección seleccionada
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(null);
  const [components, setComponents] = useState();

  const SECA = new Sections()
  const SectionCreator = new SectionsCreator()

  // Funcion para actulizar getEndpoint() -> pasar la funcion como promp a creator appbar

  const infoLoadingMap = {
    'landing/content/1': {
      title: "Inicio",
      description: "Bienvenido a la página principal. Aquí encontrarás información general y acceso a las diferentes secciones."
    },
    'landing/content/2': {
      title: "Tienda",
      description: "En esta sección puedes detallar los productos que ofreces. Organiza tu catálogo y facilita la navegación."
    },
    'landing/content/3': {
      title: "Nosotros",
      description: "En esta sección puedes detallar los productos que ofreces. Organiza tu catálogo y facilita la navegación."
    },
    'landing/content/4': {
      title: "Catálogo",
      description: "Aquí puedes administrar tu catálogo de productos, incluyendo descripciones, imágenes y precios."
    },
    'landing/content/5': {
      title: "Servicios",
      description: "Describe los servicios que ofreces de manera clara y atractiva para captar la atención de tus clientes."
    },
    'landing/content/6': {
      title: "Blog",
      description: "Describe los servicios que ofreces de manera clara y atractiva para captar la atención de tus clientes."
    },
    'landing/content/7': {
      title: "Locación",
      description: "Describe los servicios que ofreces de manera clara y atractiva para captar la atención de tus clientes."
    },
    // Agrega más secciones según sea necesario
  };

  const endpointMap = {
    '/landing/root': 'landing/content/1',
    '/landing/store': 'landing/content/2',
    '/landing/about': 'landing/content/3',
    '/landing/catalog': 'landing/content/4',
    '/landing/services': 'landing/content/5',
    '/landing/blog': 'landing/content/6',
    '/landing/location': 'landing/content/7',
  };


  const currentPath = location.pathname;
  const info = infoLoadingMap[currentPath] || { title: 'Sección no encontrada', description: 'No se ha encontrado la sección solicitada.' };

  const [noComponents, setNoComponents] = useState();


  //console.log('Estas en esta ruta:' + currentPath);
  //console.log("Valor de noComponents:", noComponents);

  function getEndpoint() { // La sección a la que queremos irnos
    const endpoint = endpointMap[location.pathname] || 'default/endpoint';
    //console.log("Estás en el endpoint:", endpoint);
    return endpoint;
  }

  async function getComponents() {
    const endpoint = getEndpoint();
    let getComponent = new BaseService(BASE_PATH, endpoint);
    let response = await getComponent.getAll();

    if (response.status === 200 && response.data.length > 0) {
      //console.log('Si regresa componentes Y noComponents es falso ');
      console.log('Datos que trajo: ', response.data.length)
      setComponents(response.data);
      setNoComponents(false);
    } else {
      console.log('Datos que trajo: ', response.data.length)
      console.log('noComponentes esta en verdadero por que no existen componente en esta vista');
      setNoComponents(true);
    }
  }
  // Llamamos a la función al montar el componente
  useEffect(() => {
    getComponents();
  }, []);


  useEffect(() => {
    setLoading(true);
    setList(SECA.getSectionList())
    console.log('length', list)
    UpdateSectionsList()
    UpdateStyles()
    setLoading(false);

  }, [view]);




  const UpdateStyles = () => {
    (async () => {
      let useBusines = new BusinessSettings();
      let response = await useBusines.getAllByIdAuth("1", logout);
      setPages(response.data);
    })();
  };

  const UpdateSectionsList = async () => {
    setLoading(true)
    let get = new SectionsCreator();
    /* let response = await get.getAllAuth(logout); */
    let irequestFilter = []
    irequestFilter.push({ 'key': 'view', 'value': view })
    let response = await get.filter(irequestFilter, logout);
    setSections(response.data);
    getComponents();
    setLoading(false)
  }

  const handleDeleteSection = async (id) => {
    let deleteUser = new SectionsCreator();
    let response = await deleteUser.deleteAuth(id, logout);
    sendNotification(UseerrorOrsuccess(response))
    UpdateSectionsList();
  }

  const UpDownSection = async (id, formData) => {

    setLoading(true)
    let updateComment = new SectionsCreator();
    const response = await updateComment.editDataAuth(id, formData, logout);
    UpdateSectionsList()

  }

  //==================For the ModalRight about "Banner"
  const [openModal, setOpenModal] = useState(false);


  const handleOpenModal = (id, type, index) => {
    console.log("ID de la sección:", id);
    setViewId(id);
    setSelectedSection(type);
    setSelectedSectionIndex(index);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  //====================

  //====================For the background and content buttons
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  //=================================

  /*----------Estados para manejar el DialogModal ------------*/
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmationData, setConfirmationData] = useState(null);
  const [skeleton, setSkeleton] = useState(false)
  //const section = sections.find(section => section.id === confirmationData.id);

  const handleOpenDialog = (id) => {
    setConfirmationData(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const renderSkeleton = (type, component) => {
    switch (type) {
      case 0: // Banner
        return <Skeleton variant="rectangular" animation="wave" width="100%" height={300} sx={{ borderRadius: '5px' }} />;
      case 1: // Texto + Imágenes
        return (
          <>

            {component?.justify_content === 'left' ? (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Skeleton variant="rectangular" animation="wave" width="100%" height={300} sx={{ borderRadius: '5px' }} />
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center" height="100%">
                    {component?.subcomponent[0]?.label_active === true ? (
                      <Skeleton variant="text" width="20%" height="15%" />
                    ) : (
                      null
                    )}
                    <Skeleton variant="text" width="30%" height="25%" />
                    <Skeleton variant="text" width="50%" height="20%" />
                    {component?.subcomponent[0]?.button_active === true ? (
                      <Skeleton
                        variant="text"
                        width="35%"
                        height="15%"
                        sx={{ borderRadius: '20px', marginLeft: '20px' }}
                      />
                    ) : (
                      null
                    )}
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center" height="100%">
                    <Skeleton variant="text" width="20%" height="15%" />
                    <Skeleton variant="text" width="30%" height="25%" />
                    <Skeleton variant="text" width="50%" height="20%" />
                    <Skeleton variant="text" width="35%" height="15%" sx={{ borderRadius: '20px', marginRight: '20px' }} />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Skeleton variant="rectangular" animation="wave" width="100%" height={300} sx={{ borderRadius: '5px', marginTop: '20px' }} />
                </Grid>
              </Grid>
            )}
          </>
        );
      case 2: // Citas/Testimonios
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} >
                <Skeleton variant="circular" animation="wave" width={140} height={140} sx={{ margin: '0 auto' }} />
              </Grid>

              <Grid item xs={12} >
                <Skeleton variant="text" width="70%" sx={{ margin: '0 auto' }} />
              </Grid>
              <Grid item xs={12} >
                <Skeleton variant="text" width="40%" sx={{ margin: '0 auto' }} />
              </Grid>
              <Grid item xs={12} sx={{ margin: '0 auto' }} >
                <Box display="flex" justifyContent="center" alignItems="center">
                  {component.subcomponent.map((_) => (
                    <Skeleton variant="circular" animation="wave" width={15} height={15} sx={{ marginLeft: 0.5 }} />
                  ))}
                </Box>

              </Grid>
            </Grid>
          </>
        );
      case 3: // Galeria
        return (
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="text" width="50%" height={80} />
            </Grid>
            {component.subcomponent.map((_) => (
              <Grid item xs={4}>
                <Skeleton variant="rectangular" animation="wave" width="100%" height={350} sx={{ borderRadius: '5px' }} />
              </Grid>
            ))}
          </Grid>
        );
      case 4: // Texto
        return (
          <>
            <Skeleton variant="text" width="70%" height={80} sx={{ margin: '0 auto' }} />

            <Skeleton variant="text" width="50%" height={80} sx={{ margin: '0 auto' }} />

          </>
        )
      case 5: // Lista
        return (
          <>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={12} >
                <Skeleton variant="text" width="60%" height={80} sx={{}} />
              </Grid>
              {component.subcomponent.map((_) => (
                <Grid item xs={12} sm={6} md={3}>
                  <Card>
                    <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                    <Skeleton variant="text" width="70%" height={40} sx={{ margin: '10px auto' }} />
                    <Skeleton variant="text" width="50%" height={40} sx={{ margin: '0 auto' }} />
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        );
      case 6: // Marcas
        return (
          <>
            <Grid container spacing={2} >
              <Grid item xs={12} >
                <Skeleton variant="text" width="40%" height={80} />
              </Grid>
              {component.subcomponent.map((_) => (
                <Grid item xs={12} sm={6} md={3}>
                  <Skeleton sx={{ height: 190, borderRadius: '5px' }} animation="wave" variant="rectangular" />
                </Grid>
              ))}

            </Grid>
          </>
        );
      case 7: // FAQ
        return (
          <>
            <Grid container  >
              {component.subcomponent.map((_) => (
                <Grid item xs={12}>
                  <Skeleton variant="text" width="70%" height={80} animation="wave" />
                </Grid>
              ))}

            </Grid>
          </>
        );
      case 8: // Categorias
        return (
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="text" width="50%" height={100} sx={{ margin: '0 auto' }} />
            </Grid>
            {component.subcomponent.map((_) => (
              <Grid item xs={4} sx={{ paddingBottom: '3%' }}>
                <Skeleton variant="rectangular" width="100%" height={70} />
              </Grid>
            ))}
          </Grid>
        );
      default:
        return <Skeleton variant="text" width="100%" />;
    }
  };


  const [hover, setHover] = useState(null);


  //=================================CALIZ DE CREATOR============//

  return (
    <>

      <CreatorAppBar getEndpoint={getEndpoint} /> {/* ESTA ES LA NAV BAR DE KESKICREATOR*/}


      {list && <AddSeccion open={open} setOpen={setOpen} seccionList={list} SECA={SECA} view={view} update={UpdateSectionsList} setSkeleton={setSkeleton} />}
      <Grid Container>

        <Grid item xs={12}>
          {/* <Container maxWidth="lg">
            {components ? (
              components?.map((element, index) => (
                  <Grid key={index}>
                    {SECA.sections[element.type]?.component(element)}
                  </Grid>
              ))
            ) : (
              console.log('No components')
            )}       
            </Container> */}
          {/* <Grid item xs={12} mt={"16px"}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  mt: 15
                }}
              >
                <Typography variant="h6">Secciones</Typography>
                <Button onClick={() => setOpen(true)}>Agregar</Button>
              </Box>
              <Divider sx={{ height: '1px', borderColor: "text.primary", backgroundColor: "text.primary", }} />
            </Grid> */}
          <Stack direction="column" >
            <ModalRight title={`${selectedSectionIndex + 1} - ${list[selectedSection]}`}
              open={openModal}
              setOpen={setOpenModal}
            >
              <TabStyles
                backgroundContent="Background"
                contentContent="Contenido"
                activeTab={activeTab}
                handleTabChange={handleTabChange}
              >
                {activeTab === 0 && (
                  <Box>
                    <DetailCreator view={viewId} detailsUpdate={getComponents} />
                  </Box>
                )}
                {activeTab === 1 && (
                  <Box>
                    <DetailCreatorElement view={viewId} detailsUpdate={getComponents} />
                  </Box>
                )}
              </TabStyles>
            </ModalRight>
            {sections && (
              <>
                {confirmationData && (
                  <ConfirmAction
                    open={openDialog}
                    handleOpen={handleOpenDialog}
                    handleClose={handleCloseDialog}
                    title={`Eliminar sección`}
                    content={`¿Estás seguro que deseas eliminar esta seccion?`}
                    disagreeText="Cancelar"
                    agreeText="Eliminar"
                    service={SectionCreator}
                    id={confirmationData.id}
                    getCurrent={UpdateSectionsList}
                  />
                )}
                <Box
                  sx={{ paddingTop: '60px' }}
                >
                  {sections.map((row, index) => {
                    // Usa una clave única basada en row.id o, si no existe, en row.type y index
                    const uniqueKey = row.id ? `${row.id}-${row.type}` : `${row.type}-${index}`;

                    return (

                      <div key={uniqueKey} >
                        <div
                          onMouseEnter={() => setHover(index)}
                          onMouseLeave={() => setHover(null)}

                        >
                          <Box
                            sx={{
                              position: "relative", // Necesario para posicionar los botones dentro de este contenedor
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: "8px",
                                backgroundColor: "#60269E",
                                borderRadius: ".3em",
                                position: "absolute", // Los botones se posicionan encima del contenido
                                top: "20px", // Ajusta la posición de los botones dentro de la sección
                                right: "30px", // Ajusta la posición a la derecha
                                opacity: hover === index ? 1 : 0, // Solo muestra los botones de la sección con hover
                                visibility: hover === index ? "visible" : "hidden", // Hacerlos visibles solo para la sección en hover
                                transition: "opacity 0.3s, visibility 0.3s", // Transición suave
                              }}
                            >
                              {/* Remueve el div extra para evitar espacio adicional */}
                              <ArrowUpwardIcon
                                onClick={() => UpDownSection(row.id, { move: "down" })}
                                sx={{ cursor: "pointer", margin: "0 6px", color: 'white' }}
                              />
                              <ArrowDownwardIcon
                                onClick={() => UpDownSection(row.id, { move: "up" })}
                                sx={{ cursor: "pointer", margin: "0 6px", color: 'white' }}
                              />
                              <EditIcon
                                onClick={() => handleOpenModal(row.id, row.type, index)}
                                sx={{ cursor: "pointer", margin: "0 6px", color: 'white' }}
                              />
                              <CloseIcon
                                onClick={() => handleOpenDialog({ id: row.id, title: row.title })}
                                sx={{ cursor: "pointer", margin: "0 6px", color: 'white' }}
                              />
                            </Box>

                            {
                              noComponents ? (
                                <GenericSection
                                  title={"Prueba de title"}
                                  description={"Prueba de descripcion"}
                                />
                              ) : (
                                components && (
                                  <Grid key={`${components[index]?.type}-${index}`}>
                                    {loading ? (
                                      // Mostrar el skeleton dependiendo del tipo mientras esté cargando
                                      <Container sx={{ width: '1200px', height: '728px' }}>
                                        {renderSkeleton(components[index]?.type, components[index]?.component)}
                                      </Container>
                                    ) : (
                                      // Renderiza el componente solo si está disponible
                                      SECA.sections[components[index]?.type]?.component(components[index])
                                    )}
                                  </Grid>
                                )
                              )
                            }
                            {skeleton && <Skeleton variant="rectangular" animation="wave" width="100%" height={300} sx={{ borderRadius: '5px' }} />}
                          </Box>
                        </div>
                      </div>
                    );
                  })}
                </Box>
                <Grid item xs={12} mt={"16px"}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      mt: 15,
                      mb: 15,
                    }}
                  >

                    <LoadingButton
                      loading={loading}
                      type="submit"
                      color="primary"
                      variant="contained"
                      onClick={() => setOpen(true)}
                      sx={{
                        mx: "auto",
                        fontSize: "16px",
                        color: "white",
                        padding: "12px 24px",
                        borderRadius: "30px",
                      }}
                    >
                      Agregar nueva sección
                    </LoadingButton>
                  </Box>
                  <Divider sx={{ height: '1px', borderColor: "text.primary", backgroundColor: "text.primary", }} />
                </Grid>
              </>
            )}



          </Stack>
        </Grid>
        {!sections && loading && (
          <Grid container justifyContent="center" p={"24px"}>
            <CircularProgress />
          </Grid>
        )}


      </Grid >

    </>
  );
};




const AddSeccion = ({ open, setOpen, seccionList, SECA, update, view, setSkeleton }) => {
  const [layout, setLayout] = useState("")
  /*   console.log(Object.values(seccionList)) */
  const [msg, sendNotification] = useNotification();
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);


  const navigate = useNavigate();
  const { logout } = useAuth();

  const setChanges = (e) => {
    console.log(e.target.value)
    setLayout(e.target.value)
  }

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  const PostNewSection = async (id, formData) => {

    let form = {
      name: seccionList[layout],
      view: view,
      type: layout,
      component_layout: activeIndex,
      component_type: SECA.getLayoutsFromSection(layout).getFootPrint(activeIndex).req.type || 0
    }
    console.log(form)
    console.log(form)

    let updateComment = new SectionsCreator();
    const response = await updateComment.postDataAuth(form, logout);


    sendNotification(UseerrorOrsuccess(response))
    setOpen(false); // Close the modal when you add the section
    update();
    //navigate(`detail/${response.data.id}`)

  }

  const PostNewSectionWithIA = async (id, formData) => {
    setLoading(true)
    setSkeleton(true)
    let form = {
      name: seccionList[layout],
      view: view,
      type: layout,
      component_layout: activeIndex,
      component_type: SECA.getLayoutsFromSection(layout).getFootPrint(activeIndex).req.type || 0
    }
    console.log(form)
    console.log(form)
    setOpen(false);
    let updateComment = new SectionsCreatorWithIA();
    const response = await updateComment.postDataAuth(form, logout);
    // Close the modal when you add the section
    setLoading(false)
    scrollToBottom();
    update();
    setSkeleton(false)


  }



  const handleBoxClick = (index) => {
    setLayout(index); // Actualiza el layout seleccionado
  };


  return (

    <ModalRight setOpen={setOpen} open={open} title="Catálogo de secciones">
      <Grid container>


        <Typography sx={{ fontWeight: 'bold' }}>
          Selecciona una sección
        </Typography>
        {/* <Stack spacing={1}>
        
          <Typography variant="caption" sx={{ color: 'gray' }}>
            Tienes 20 tokens
          </Typography>
          <Typography variant="caption" sx={{ color: 'gray' }}>
            Generar este componente consumira un token
          </Typography>
        </Stack>*/}


        <Grid item xs={12} mt="1em">


          <Grid item xs={12} mt={"16px"}>

            {seccionList && Object.entries(seccionList).map(([index, data]) => (
              <Box
                key={index}
                sx={{
                  marginBottom: 4,
                  padding: 2,
                  border: '5px solid lightgray',
                  borderRadius: 1,
                  cursor: 'pointer',
                  borderColor: layout === index ? '#60269E' : 'lightgray',
                }}
                onClick={() => handleBoxClick(index)}
              >
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                  {data}
                </Typography>

                <CarruselLayouts layout={index} SECA={SECA} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
              </Box>
            ))}
          </Grid>
          <Box
            sx={{ paddingTop: '70px' }}
          />

          <Grid
            item
            xs={12}
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              left: 0,
              backgroundColor: "white",
              padding: "16px",
              zIndex: 10,
              borderRadius: "8px",
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            {layout !== null && (
              <>
                {/*<Typography variant="caption" sx={{ marginBottom: 1, display: "block" }}>
                  Generar esta sección puede llevar algo de tiempo
                </Typography>*/}
                <Grid container direction="row" spacing={1} sx={{ justifyContent: "flex-end", alignItems: "center", mt: '1em' }}>
                  <Grid>
                    <LoadingButton
                      onClick={() => PostNewSection(layout)}
                      variant="outlined"
                      sx={{ marginRight: 2, backgroundColor: "white", borderRadius: "15px" }}
                      loading={loading}
                    >
                      Agregar
                    </LoadingButton>
                  </Grid>

                  {/*<Grid>
                    <LoadingButton
                      onClick={() => PostNewSectionWithIA(layout)}
                      variant="contained"
                      endIcon={<AutoAwesomeIcon />}
                      sx={{ borderRadius: "15px" }}
                      loading={loading}
                    >
                      Generar con IA
                    </LoadingButton>
                  </Grid>*/}

                </Grid>
              </>
            )}
          </Grid>


        </Grid>
      </Grid>
    </ModalRight >
  );
}

const json = {
  title: 'Hola JSON',
  layout: 0,
  backgroundImage: 'https://img.freepik.com/free-vector/gradient-grainy-texture_23-2148981502.jpg?w=1380&t=st=1683581727~exp=1683582327~hmac=d5affc8319cef6a52eef12e756657ac1ff51c2e23c661b296fce256271d0b8aa',
  content: [
    {
      title: 'Hola child'
    }
  ]
}

const json2 = {
  title: 'Hola JSON',
  layout: 0,
  backgroundImage: 'https://img.freepik.com/free-vector/gradient-grainy-texture_23-2148981502.jpg?w=1380&t=st=1683581727~exp=1683582327~hmac=d5affc8319cef6a52eef12e756657ac1ff51c2e23c661b296fce256271d0b8aa',
  content: [
    {
      title: 'Hola lgjasdklfgjasdkljasflñkasjklñadfjasdfkl'
    }
  ]
}

const CarruselLayouts = ({ layout, SECA, activeIndex, setActiveIndex }) => {
  const [ComponentRender, setComponentRender] = useState("")
  const [LayoutListLength, setLayoutListLength] = useState("")
  const [item, setItem] = useState("")
  /*   var ComponentRender; */
  /* var LayoutListLength; */

  useEffect(() => {
    console.log(layout)
    setComponentRender(SECA.getLayoutsFromSection(layout))
    let temRender = SECA.getLayoutsFromSection(layout)
    setLayoutListLength(temRender.getFootPrint()) /* getFootPrint  layoutList*/
    console.log('list length', LayoutListLength)
    setItems(temRender.layoutLength(), SECA.getLayoutsFromSection(layout))
  }, [layout])

  const setItems = (LayoutListLength, ComponentRender) => {
    const list = [];
    console.log("layout list lenght", LayoutListLength)
    for (let i = 0; i <= LayoutListLength - 1; i++) {  /* ComponentRender.getFootPrint(i).req.type */
      /* console.log(i) */
      list.push(<div className="item" data-value={i}> <img src={ComponentRender.getFootPrint(i).img} style={{ width: "100%" }} /> </div>) /* {ComponentRender.component(i==0?json:json2,  i)}  </div> */
    }

    setItem(list)
  }

  return (
    <>
      {/*   { LayoutListLength && ComponentRender &&  <Box sx={{height: "200px"}}>
        { Object.keys([...Array(LayoutListLength)]).map((index)=>(
           <Box sx={{height: "200px", width:"50%"}}> {ComponentRender.component(json,  index)} sss</Box>
        ))

        }
        </Box>} */}
      {/*     { Object.keys([...Array(LayoutListLength)]).map((index)=>(
         <>fasdfasf asdfasdf {index}</>
        ))

        } */}
      <>{item && <Carousel itemm={item} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />}</>

      {/* <>{ComponentRender.component({content:json.content, layout: 0} )}</> */}
    </>
  );
}
const thumbItems = (items, [setThumbIndex, setThumbAnimation]) => {
  return items.map((item, i) => (
    <div className="thumb" onClick={() => (setThumbIndex(i), setThumbAnimation(true))}>
      {item}
    </div>
  ));
};

const Carousel = ({ itemm, activeIndex, setActiveIndex }) => {
  /* const [activeIndex, setActiveIndex] = useState(0); */
  const [items, setItems] = useState(itemm);

  useEffect(() => {
    setItems(itemm)
  }, [itemm])

  const slidePrev = () => {

    if (0 <= activeIndex - 1) { setActiveIndex(activeIndex - 1); }
  };
  const slideNext = () => {

    if (activeIndex + 1 <= itemm.length - 1) {
      setActiveIndex(activeIndex + 1);

    }
  };
  const syncActiveIndex = ({ item }) => setActiveIndex(item);
  useEffect(() => {

  })

  return (

    <>

      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {/*  <Button onClick={slidePrev}>Prev</Button> */}
        {/*<IconButton onClick={slidePrev} >
          <ArrowBackIosIcon sx={{ color: 'text.secondary' }} />
        </IconButton>*/}
        <Box sx={{ width: "90%" }}>
          <AliceCarousel
            mouseTracking
            disableDotsControls
            disableButtonsControls
            items={items}
            activeIndex={activeIndex}
            onSlideChanged={syncActiveIndex}
          />
        </Box>
        {/*<IconButton onClick={slideNext} >
          <ArrowForwardIosIcon sx={{ color: 'text.secondary' }} />
        </IconButton>*/}
        {/* <Button onClick={slideNext}>Next</Button> */}
      </Box>
      {/*      <div className="thumbs">


        
        
     </div>
      */}
    </>
  );
};

export default Creator;
