import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from "@mui/material";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FilterIcon from "@mui/icons-material/Filter";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../../../scss/storeTheme";
import images from "../../../assets/index";
import { Input } from "../../catalog/articleDetail/articleModels";
import { ImageContainer } from "./imageContainer";
import { useImages } from "./useImages";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ModalImages = ({ data, UpdateInfo, onClose }) => {
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState(0);

  const {
    saveImages,
    randomImages,
    changeInput,
    fileInput,
    isUploading,
    saveNewImage,
    deleteImage,
    searchMoreImages,
  } = useImages({ setValue });

  const [page, setPage] = useState(1); // Página actual
  const [totalPages, setTotalPages] = useState(1); // Total de páginas

  // Simulando los datos de las imágenes
  const totalPerPage = 10;

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={open || false}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            className="box"
            item
            xs={10}
            sx={{
              bgcolor: "background.paper",
              height: "902px",
              overflow: "hidden",
            }}
          >
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item xs={11}>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  sx={{ px: "10px", m: 0 }}
                >
                  <strong>Libreria</strong>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <IconButton
                    sx={{ p: 0, m: 0 }}
                    className="icon"
                    onClick={() => setOpen(false)}
                    aria-label="delete"
                  >
                    <CloseIcon sx={{ color: "text.primary", p: 0, m: 0 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={"16px"}>
              <Box>
                <Box
                  sx={{ borderBottom: 1, borderTop: 1, borderColor: "divider", width: '100%' }}
                >
                  <Tabs
                    value={value}
                    onChange={(e, newValue) => setValue(newValue)}
                  >
                    <Tab
                      icon={<FilterIcon />}
                      iconPosition="start"
                      sx={{
                        fontSize: "11px",
                        paddingY: "10px",
                        minHeight: "20px",
                        fontWeight: '600',
                      }}
                      label="Imagenes guardadas"
                      {...a11yProps(0)}
                    />
                    <Tab
                      icon={<CropOriginalIcon />}
                      iconPosition="start"
                      sx={{
                        fontSize: "11px",
                        paddingY: "10px",
                        minHeight: "20px",
                        fontWeight: '600',
                      }}
                      label="Unsplash"
                      {...a11yProps(1)}
                    />
                    <Tab
                      icon={<FileUploadIcon />}
                      iconPosition="start"
                      sx={{
                        fontSize: "11px",
                        paddingY: "10px",
                        minHeight: "20px",
                        fontWeight: '600',
                      }}
                      label="Subir una imagen"
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <ImageContainer
                    images={saveImages}
                    savedImages
                    deleteImage={deleteImage}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <ImageContainer
                    images={randomImages}
                    saveNewImage={saveNewImage}
                    searchMoreImages={searchMoreImages}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <Grid
                    container
                    xs={12}
                    sx={{
                      justifyContent: "center",
                      height: "600px",
                      paddingTop: "50px",
                    }}
                  >
                    <Grid item xs={4}>
                      <Grid
                        container
                        justifyContent="center"
                        xs={12}
                        rowSpacing={2}
                      >
                        {!isUploading ? (
                          <>
                            <Grid
                              item
                              sx={{ display: "flex", justifyContent: "center" }}
                              xs={12}
                            >
                              <img src={images.upload} alt="upload_image" />
                            </Grid>
                            <Grid
                              item
                              sx={{ display: "flex", justifyContent: "center" }}
                              xs={12}
                            >
                              <Input
                                hidden
                                type="file"
                                multiple
                                onChange={changeInput}
                                ref={fileInput}
                              ></Input>
                              <Button
                                variant="contained"
                                sx={{ backgroundColor: "#60269E" }}
                                onClick={() => fileInput.current.click()}
                              >
                                Examinar
                              </Button>
                            </Grid>
                            <Grid
                              item
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                              }}
                              xs={12}
                            >
                              <p>O arrastra archivos aquí</p>
                              <span style={{ color: "#9C9898" }}>
                                PNG, JPG, no mas de 300 MB
                              </span>
                            </Grid>
                          </>
                        ) : (
                          <CircularProgress />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </CustomTabPanel>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </ThemeProvider>
  );
};
