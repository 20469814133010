import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import GenericTable from "../../../components/GenericTable";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DropMenu from "../../../components/dropMenu";
import { GenericEmptyTable } from "../../GenericEmptyTable";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import CurrencyFormat from "react-currency-format";
import { CardHeader } from "@mui/material";

const ArticlesListPreview = (props) => {
  const { data } = props;

  const [open, setOpen] = useState(false);

  return (
    <>
      {data.articles ? (
        <div className="articles-table">
          <GenericTable
            titles={[
              "Nombre / # parte ",
              "Modelo / Presentación",
              "Cantidad",
              data.articles[0].price ? "Precio" : "Costo",
              "Subtotal",
            ]}
          >
            {data.articles.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover
                onDoubleClick={() => {
                  /*      window.localStorage.setItem("menu", 10);
              navigate("detail"); */
                }}
              >
                <TableCell align="left" sx={{ maxWidth: "2000px" }}>
                  <CardHeader
                    sx={{ m: 0, p: 0 }}
                    title={
                      <Typography
                        component="div"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row.model.product.name}
                      </Typography>
                    }
                    subheader={row.model.product.sku}
                  />
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: "120px" }}>
                  <CardHeader
                    sx={{ m: 0, p: 0 }}
                    title={
                      <Typography
                        component="div"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row.model.name}
                      </Typography>
                    }
                    subheader={row.presentation.name}
                  />
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: "120px" }}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row.quantity}
                  </Typography>
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: "150px" }}>
                  <CurrencyFormat
                    value={row.price ? row.price : row.cost}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: "150px" }}>
                  <CurrencyFormat
                    value={
                      row.price
                        ? row.price * row.quantity
                        : row.cost * row.quantity
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </TableCell>
              </TableRow>
            ))}
          </GenericTable>
        </div>
      ) : (
        <GenericEmptyTable msg={"No hay artículos agregados"} />
      )}
    </>
  );
};

export default ArticlesListPreview;
