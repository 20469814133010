import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { TextField, FormHelperText } from "@mui/material";
import BasicModal from "../../../components/GenericModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { initValAddArticle, valSchAddArticle } from "../../../utils/formHelper";
import CategoryManages from "../../../api/categoryManages";
import Products from "../../../api/productsManages";
import { useAuth } from "user-context-fran-dev";
import CountWords from "../../../components/CountWords";

import { useNavigate } from "react-router-dom";
import { GenericEmptyTable } from "../../../components/GenericEmptyTable";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";
import { GenericDrawer } from "../../../components/keskiCreator/genericDrawer";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const AddArticle = ({ udateProducts }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(null);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState({
    top: false,
    top1: false,
    right: false,
    right1: false,
  });

  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, [open]);

  const getCategory = () => {
    (async () => {
      let getCategory = new CategoryManages();
      let response = await getCategory.getAllAuth(logout);
      setCategory(response.data);
    })();
  };

  const toggleDrawer = (anchor, open) => {
    console.log("Toggling drawer", anchor, open);
    setOpenDrawer((prevState) => ({
      ...prevState,
      [anchor]: open, // Esto actualizará solo la clave correspondiente
    }));
  };

  const formik = useFormik({
    initialValues: initValAddArticle(),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchAddArticle()),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);
      console.log(formData);
      let setProduct = new Products();
      let response = await setProduct.postDataAuth(formData, logout);
      sendNotification(UseerrorOrsuccess(response));

      if (response) {
        window.localStorage.setItem("menu", 10);
        setLoading(false);
        setOpen(false);
        navigate(`detail/${response.data.id}`);
      }
      setLoading(false);
      resetForm();
      setOpen(false);
    },
  });

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          getCategory();
          toggleDrawer("right1", true);
        }}
        sx={{ height: "30px", borderRadius: "60px" }}
      >
        Agregar
      </Button>
      <GenericDrawer
        open={openDrawer}
        toggleDrawer={toggleDrawer}
        anchor={"right1"}
        drawerWidth="30%"
      >
        <Box p={"16px"} component={"form"} onSubmit={formik.handleSubmit}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: "16px",
            }}
          >
            <Typography fontSize={"20px"} p={"8px 0"}>
              <b>Agregar artículos</b>
            </Typography>

            <IconButton onClick={() => toggleDrawer("right1", false)}>
              <CloseSharpIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Typography>DATOS DEL ARTICULO (e.g., Playera)</Typography>
          <Grid>
            <TextField
              fullWidth
              name="name"
              values={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              label="Nombre del artículo"
              inputProps={{ maxLength: 100 }}
              margin="dense"
              size="small"
              variant="outlined"
              autoComplete="none"
              helperText={formik.touched.name ? formik.errors.name : ""}
            />
            <CountWords
              length={formik.values.name.length.toString()}
              max="100"
            />

            {category && (
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-filled-label">
                  Asignar Categoría
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  name="category"
                  label="Asignar Categoría"
                  value={formik.values.category}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.category && Boolean(formik.errors.category)
                  }
                >
                  <MenuItem value="">
                    <em>Categoría</em>
                  </MenuItem>
                  {category.map((data) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formik.touched.category ? formik.errors.category : ""}
                </FormHelperText>
              </FormControl>
            )}
            {!category && (
              <GenericEmptyTable msg="Para continuar añade una categoría primero" />
            )}
          </Grid>
          <Divider sx={{ m: "16px 0" }} />
          <Typography>MODELO (e.g., Rojo)</Typography>
          <Typography>
            El nombre del modelo representa una variación específica del
            artículo principal, como un color, un diseño o un estilo. Ejemplo:
            si estás agregando una playera, un modelo podría ser 'Rojo' o
            'Edición limitada'.
          </Typography>
          <TextField
            label="Nombre del modelo"
            size="small"
            fullWidth
            margin="normal"
          />
          <Divider sx={{ m: "16px 0" }} />
          <Typography>PRESENTACION (e.g., Chica)</Typography>
          <Typography>
            El nombre de la presentación describe una característica específica
            de un modelo, como la talla, el empaque o la versión. Ejemplo: si
            tienes un modelo de playera roja, una presentación podría ser
            'Chica', 'Mediana' o 'Grande'.
          </Typography>

          {["Nombre de la presentación", "Costo", "Precio"].map((val) => (
            <TextField fullWidth label={val} margin="normal" size="small" />
          ))}

          <Grid display="flex" justifyContent={"flex-end"}>
            <LoadingButton
              loading={loading}
              disabled={category === null}
              type="submit"
              variant="contained"
              sx={{ mt: 2, borderRadius: "60px", width: "150px" }}
            >
              Agregar
            </LoadingButton>
          </Grid>
        </Box>
      </GenericDrawer>
    </>
  );
};

export default AddArticle;
