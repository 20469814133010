import React, { useState, useEffect } from "react";
import { ProductModelAvailableService } from "../../../api/productService";
import DrawerArticle from "../DrawerArticle";
import { useAuth } from "user-context-fran-dev";

export default function EditArticle(props) {
  const {
    openEdit,
    dataProduct,
    type,
    reloadDataOforder,
    orderData,
    toggleDrawer,
    dataPresentation,
  } = props;
  const [dataProductAva, setDataProduct] = useState("");
  /*   const [localDataProduct, setLocalDataProduct] = useState(""); */
  const [loading, setLoading] = useState(true);
  const { logout } = useAuth();
  console.log("orderDAta", orderData);
  console.log("dataProduct", dataProduct);

  const findArticle = () => {
    for (var article in orderData.articles) {
      console.log(orderData.articles[article]);
      if (orderData.articles[article].id === dataProduct) {
        return orderData.articles[article];
      }
    }
  };

  const getAvailabeProduct = () => {
    (async () => {
      let irequestFilter = [];
      irequestFilter.push({
        key: "product",
        value: `${dataProduct.model.product.id}`,
      });
      irequestFilter.push({
        key: "mdl",
        value: `${dataProduct.model.id}`,
      });
      irequestFilter.push({ key: "wh", value: `${orderData.warehouse.id}` });
      let productInventory = new ProductModelAvailableService();
      let response = await productInventory.filter(irequestFilter, logout);
      setDataProduct(response?.data[0]);
      /* console.log(response.data[0].product_model[0].presentations.find((x)=> x.id == dataProduct.presentation.id) ) */
      /* find((x)=> x== dataProduct.presentation.id) */

      setLoading(false);
    })();
  };
  useEffect(() => {
    /*     setLoading(true);
        setLocalDataProduct(findArticle());
        console.log(findArticle());
        if (localDataProduct) {
          
        } */
    getAvailabeProduct();
  }, [orderData]);

  return (
    <DrawerArticle
      openDrawer={openEdit}
      type={type}
      toggleDrawer={toggleDrawer}
      Presentation={dataPresentation}
      reloadDataOforder={reloadDataOforder}
      dataProduct={dataProduct}
      close={() => toggleDrawer("right1", false)}
    />
  );
}
