import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Container from "../../layout/gridContainer";
import { Button } from "@mui/material";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { STATUSPRODUCTION, STATUSPRODUCTIONCOLOR } from "../../utils/constants";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IconButton } from "@mui/material";
import { ProductionOrder } from "../../api/productionOrder";
import { PaginationGeneric } from "react-generic-pagination";
import CircularProgress from "@mui/material/CircularProgress";
import AddTransformation from "./transformation/addTransformation";
import BasicTable from "../../components/GenericTable";
import { GenericEmptyTable } from "../../components/GenericEmptyTable";
import { DateFormat } from "../../components/DateFormat";
import Chip from "@mui/material/Chip";
import { CardHeader } from "@mui/material";
import GenericSearch from "../../components/GenericSearch/GenericSearch";
import useNexOrder from "../../hooks/nextOrder";
import { useAuth } from "user-context-fran-dev";

const InventoryTransformation = () => {
  const [category, setCategory] = useState("");
  const totalPerPage = 10;
  const [table, setTable] = useState(null);
  const [page, setPage] = useState(1); //primer request en page 1
  const [totalpages, setTotalPages] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [idOrder] = useNexOrder(table);
  const { logout } = useAuth();

  const pageSetter = (n) => {
    setPage(n);
    /* navigate(`page=${page}`) */
  };

  const getTranformations = () => {
    (async () => {
      setLoading(true);

      let irequestFilter = [];
      irequestFilter.push({ key: "page", value: `${page}` });
      irequestFilter.push({ key: "items", value: `${totalPerPage}` });
      let getTranformation = new ProductionOrder();
      let response = await getTranformation.filter(irequestFilter, logout);

      if (response) {
        setTotalPages(response.data.pagination);
        setTable(response.data.data);
        console.log(response.data);
      }

      setLoading(false);
    })();
  };

  useEffect(() => {
    navigate(`page=${page}`);
    getTranformations();
  }, [page]);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={5}>
              <GenericSearch
                Service={ProductionOrder}
                setData={setTable}
                label={"Buscar por numero de order"}
              ></GenericSearch>
            </Grid>
            <Grid item xs={0} sm={2} />

            <Grid item xs={12} sm={5}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Crear Orden
              </Button>
              {idOrder && (
                <AddTransformation
                  open={open}
                  setOpen={setOpen}
                  update={getTranformations}
                  idOrder={idOrder}
                />
              )}

              {/* <AddRecipe open={open} setOpen={setOpen} /> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          {table && (
            <>
              <BasicTable
                titles={[
                  "Orden / Fecha",
                  "Almacén",
                  "Recetas",
                  "Estatus",
                  "Más",
                ]}
              >
                {table.map((row, index) => (
                  <Row row={row} key={index} />
                ))}
              </BasicTable>
              <div className="margin">
                <PaginationGeneric
                  className="paginationStyle"
                  page={page}
                  total={totalpages}
                  pageSetter={pageSetter}
                ></PaginationGeneric>
              </div>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {loading && !table && (
            <Grid container justifyContent="center" mt={5}>
              <CircularProgress />
            </Grid>
          )}
          {!table && !loading && <GenericEmptyTable />}
        </Grid>
      </Grid>
    </Container>
  );
};
const Row = ({ row }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const beginDate = row.date_created;

  return (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          cursor: "pointer",
        }}
        hover
        onDoubleClick={() => {
          navigate(`detail/${row.id}`);
          window.localStorage.setItem("menu", 15);
        }}
      >
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography variant="subtitle1" sx={{ m: 0, p: 0 }}>
                {row.no_order}
              </Typography>
            }
            subheader={DateFormat({ beginDate })}
          />
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Typography
            variant="subtitle1"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.warehouse.name}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {row.recipe_total}
              </Typography>
            }
          />
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Chip
            label={STATUSPRODUCTION[row.status]}
            color={STATUSPRODUCTIONCOLOR[row.status]}
            /* className="chip-with" */ variant="outlined"
          />
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{ m: 0, p: 0 }}
            onClick={() => {
              if (!open) {
                /*    if (is_purchase) {
                  
                 
                } else {
             
                }
                setId(data.id) */
              }
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default InventoryTransformation;
