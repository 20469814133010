import React, { useState, useEffect } from "react";
import Container from "../../../layout/gridContainer";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import BasicTable from "../../../components/GenericTable";
import { GenericEmptyTable } from "../../../components/GenericEmptyTable";
import CircularProgress from "@mui/material/CircularProgress";
import { PaginationGeneric } from "react-generic-pagination";
import { STATUSCASHREGISTER } from "../../../utils/constants";
import { DateFormat } from "../../../components/DateFormat";

const PosTable = (props) => {
  const { table, loading, page, totalpages, pageSetter, goTo, children } =
    props;

  return (
    <Container>
      <Grid item xs={12} sm={8} md={8} lg={8}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          className="title-card"
          sx={{ paddingBottom: "2%" }}
        >
          <strong>Cajas</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={5}>
            {children}
          </Grid>
          <Grid item xs={0} sm={2} />
        </Grid>
      </Grid>
      <Grid item xs={12} mt={"32px"}>
        {table && (
          <>
            <BasicTable
              titles={[
                "Sucursal",
                "Abierto",
                "Cerrado",
                "Usuario",
                "Estado",
                "Total",
              ]}
            >
              {table.map((row, index) => (
                <Row key={index} row={row} goTo={goTo} />
              ))}
            </BasicTable>
            <Grid item xs={12} mt={2}>
              <PaginationGeneric
                className="paginationStyle"
                page={page}
                total={totalpages}
                pageSetter={pageSetter}
              />
            </Grid>
          </>
        )}
        {loading && !table && (
          <Grid container justifyContent="center" mt={5}>
            <CircularProgress />
          </Grid>
        )}
        {!table && !loading && <GenericEmptyTable />}
      </Grid>
    </Container>
  );
};

export const Row = ({ row, goTo }) => {
  return (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          cursor: "pointer",
        }}
        hover
        onDoubleClick={() => {
          goTo(row);
        }}
      >
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Typography variant="subtitle2" sx={{ m: 0, p: 0 }}>
            {row.branch.name}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <DateFormat beginDate={row.date_created} />
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.date_closed && <DateFormat beginDate={row.date_closed} />}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.user.first_name}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          {STATUSCASHREGISTER[row.status]}
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.total}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};
export default PosTable;
