import React from "react";
import { Box, Button, Grid, Link, Skeleton, Typography } from "@mui/material";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

export function GenericEmptyTable() {
  return (
    <>
      <tr>
        <th colspan="6" scope="colgroup">
          <>
            <Grid m={"32px 0"}>
              <Typography p={"16px"}>
                <b>No hay contenido disponible aún</b>
              </Typography>
              <Typography>
                {" "}
                Agrega ahora un elemento para que se muestre aquí
              </Typography>

              <Typography>
                ¿Necesitas ayuda? Checa nuestro{" "}
                <Link>contenido para más inspiración</Link>
              </Typography>
            </Grid>
          </>
        </th>
      </tr>
    </>
  );
}

export function GenericSkeletonTable() {
  return (
    <>
      <tr>
        <th colspan="6" scope="colgroup">
          <Grid
            height={"20vh"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box sx={{ width: "100%", p: "16px" }}>
              <Skeleton height={60} />
              <Skeleton animation="pulse" height={60} />
              <Skeleton animation="pulse" height={60} />
              <Skeleton animation="pulse" height={60} />
              <Skeleton animation="pulse" height={60} />
            </Box>
          </Grid>
        </th>
      </tr>
    </>
  );
}
