import React, { Component } from "react";
import {
  EditorState,
  convertToRaw,
  ContentState,
  Modifier,
  RichUtils,
} from "draft-js";
import { Editor, emoji } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html"; // sirve como te imaginaras para imprimirlo
import htmlToDraft from "html-to-draftjs";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import ReactHtmlParser from 'react-html-parser';
import convert from "htmr";
import { margin, padding } from "@mui/system";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import IconButton from "@mui/material/IconButton";
import { SketchPicker } from "react-color";

class EditorConvertToHTML extends Component {
  constructor(props) {
    super(props);

    const html = props.data;
    const contentBlock = htmlToDraft(html);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
      };
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.handleClick(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  render() {
    const { editorState } = this.state;
    const theme = window.localStorage.getItem("theme") == "dark" ? true : false;

    // Configuración de la barra de herramientas dinámica basada en la prop toolbarConfig
    const toolbarConfig = this.props.toolbarConfig || {
      options: [
        "inline",
        "blockType",
        "fontSize",
        "list",
        "textAlign",
        "colorPicker",
        "emoji",
      ],
      inline: {
        options: [
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "superscript",
          "subscript",
        ],
      },
      list: {
        options: ["unordered", "ordered"],
      },
      colorPicker: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        colors: [
          "rgb(0, 0, 0)",
          "rgb(51, 51, 51)",
          "rgb(77, 77, 77)",
          "rgb(102, 102, 102)",
          "rgb(128, 128, 128)",
          "rgb(153, 153, 153)", //NEGRO
          "rgb(204, 204, 204)",
          "rgb(227, 227, 227)",
          "rgb(237, 237, 237)",
          "rgb(247, 247, 247)",
          "rgb(255, 255, 255)",
          "rgb(240, 240, 240)", //GRIS
          "rgb(40, 50, 78)",
          "rgb(126, 150, 177)",
          "rgb(169, 188, 199)",
          "rgb(195, 208, 215)",
          "rgb(206, 221, 223)",
          "rgb(187, 206, 214)", //GRIS OSCURO
          "rgb(84, 172, 210)",
          "rgb(161, 198, 217)",
          "rgb(184, 211, 224)",
          "rgb(207, 224, 231)",
          "rgb(229, 237, 238)",
          "rgb(209, 228, 234)", //AZUL TURQUESA
          "rgb(41, 105, 176)",
          "rgb(143, 198, 223)",
          "rgb(173, 216, 230)",
          "rgb(180, 214, 232)",
          "rgb(217, 230, 240)",
          "rgb(239, 245, 250)", //AZUL
          "rgb(71, 85, 119)",
          "rgb(126, 150, 177)",
          "rgb(169, 188, 199)",
          "rgb(195, 208, 215)",
          "rgb(206, 221, 223)",
          "rgb(187, 206, 214)", //AZUL GRIS
          "rgb(65, 168, 95)",
          "rgb(139, 206, 161)",
          "rgb(180, 217, 182)",
          "rgb(220, 239, 225)",
          "rgb(238, 248, 243)",
          "rgb(207, 237, 223)", //VERDE
          "rgb(26, 188, 156)",
          "rgb(130, 199, 186)",
          "rgb(166, 210, 204)",
          "rgb(202, 236, 216)",
          "rgb(238, 246, 243)",
          "rgb(204, 232, 220)", //CELESTE/AQUA
          "rgb(184, 49, 47)",
          "rgb(216, 144, 142)",
          "rgb(231, 170, 169)",
          "rgb(244, 196, 194)",
          "rgb(252, 225, 224)",
          "rgb(249, 219, 219)", //ROJO
          "rgb(247, 218, 100)",
          "rgb(252, 234, 14)",
          "rgb(253, 241, 175)",
          "rgb(253, 248, 208)",
          "rgb(254, 252, 232)",
          "rgb(252, 251, 228)", //AMARILLO
          "rgb(251, 160, 38)",
          "rgb(253, 200, 139)",
          "rgb(254, 216, 173)",
          "rgb(254, 233, 207)",
          "rgb(254, 248, 236)",
          "rgb(255, 230, 195)", //NARANJA
          "rgb(102, 51, 0)",
          "rgb(166, 133, 100)",
          "rgb(191, 166, 133)",
          "rgb(216, 200, 166)",
          "rgb(233, 216, 200)",
          "rgb(250, 241, 233)", //CAFE
          "rgb(147, 101, 184)",
          "rgb(184, 144, 211)",
          "rgb(202, 178, 220)",
          "rgb(222, 205, 223)",
          "rgb(239, 220, 238)",
          "rgb(230, 213, 230)", //LILA
          "rgb(85, 57, 130)",
          "rgb(144, 141, 195)",
          "rgb(196, 168, 216)",
          "rgb(211, 195, 227)",
          "rgb(233, 221, 239)",
          "rgb(215, 204, 235)", //MORADO
          "rgb(255, 0, 255)",
          "rgb(255, 204, 255)",
          "rgb(255, 230, 255)",
          "rgb(255, 243, 255)",
          "rgb(255, 247, 255)",
          "rgb(255, 250, 255)", //ROSA/MAGENTA
        ],
      },
      emoji: {
        icon: emoji,
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        emojis: [
          "😀",
          "😁",
          "😂",
          "😃",
          "😉",
          "😋",
          "😎",
          "😍",
          "😗",
          "🤗",
          "🤔",
          "😣",
          "😫",
          "😴",
          "😌",
          "🤓",
          "😛",
          "😜",
          "😠",
          "😇",
          "😷",
          "😈",
          "👻",
          "😺",
          "😸",
          "😹",
          "😻",
          "😼",
          "😽",
          "🙀",
          "🙈",
          "🙉",
          "🙊",
          "👼",
          "👮",
          "🕵",
          "💂",
          "👳",
          "🎅",
          "👸",
          "👰",
          "👲",
          "🙍",
          "🙇",
          "🚶",
          "🏃",
          "💃",
          "⛷",
          "🏂",
          "🏌",
          "🏄",
          "🚣",
          "🏊",
          "⛹",
          "🏋",
          "🚴",
          "👫",
          "💪",
          "👈",
          "👉",
          "👉",
          "👆",
          "🖕",
          "👇",
          "🖖",
          "🤘",
          "🖐",
          "👌",
          "👍",
          "👎",
          "✊",
          "👊",
          "👏",
          "🙌",
          "🙏",
          "🐵",
          "🐶",
          "🐇",
          "🐥",
          "🐸",
          "🐌",
          "🐛",
          "🐜",
          "🐝",
          "🍉",
          "🍄",
          "🍔",
          "🍤",
          "🍨",
          "🍪",
          "🎂",
          "🍰",
          "🍾",
          "🍷",
          "🍸",
          "🍺",
          "🌍",
          "🚑",
          "⏰",
          "🌙",
          "🌝",
          "🌞",
          "⭐",
          "🌟",
          "🌠",
          "🌨",
          "🌩",
          "⛄",
          "🔥",
          "🎄",
          "🎈",
          "🎉",
          "🎊",
          "🎁",
          "🎗",
          "🏀",
          "🏈",
          "🎲",
          "🔇",
          "🔈",
          "📣",
          "🔔",
          "🎵",
          "🎷",
          "💰",
          "🖊",
          "📅",
          "✅",
          "❎",
          "💯",
        ],
      },
    };
    const editorStyles = {
      editor: {
        margin: "1rem",
      },
    };
    return (
      <div>
        <Editor
          wrapperClassName={editorStyles.wrapper}
          editorState={editorState}
          editorClassName={editorStyles.editor}
          onEditorStateChange={this.onEditorStateChange}
          toolbar={toolbarConfig}
          placeholder="Escribe aqui"
        />
      </div>
    );
  }
}

export default EditorConvertToHTML;
